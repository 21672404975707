import {
  FETCH_BOOK_RIBBONS,
  FETCH_BOOK_RIBBONS_SUCCESS,
  FETCH_BOOK_RIBBONS_ERROR,
  FETCH_RIBBON_BOOKS,
  FETCH_RIBBON_BOOKS_SUCCESS,
  FETCH_RIBBON_BOOKS_ERROR,
  FETCH_BOOK_DETAILS,
  FETCH_BOOK_DETAILS_SUCCESS,
  FETCH_BOOK_DETAILS_ERROR,
  BROWSE_BOOKS,
  BROWSE_BOOKS_SUCCESS,
  BROWSE_BOOKS_ERROR,
  FETCH_HOME_RIBBONS,
  FETCH_HOME_RIBBONS_SUCCESS,
  FETCH_HOME_RIBBONS_ERROR,
  FETCH_RELATED_BOOKS,
  FETCH_RELATED_BOOKS_SUCCESS,
  FETCH_RELATED_BOOKS_ERROR,
  FETCH_PREVIEW_RIBBONS,
  FETCH_PREVIEW_RIBBONS_SUCCESS,
  FETCH_PREVIEW_RIBBONS_ERROR,
} from '../actionTypes';

export const fetchBookRibbons = () => ({
  type: FETCH_BOOK_RIBBONS,
});

export const fetchBookRibbonsSuccess = (ribbons) => ({
  type: FETCH_BOOK_RIBBONS_SUCCESS,
  payload: ribbons,
});

export const fetchBookRibbonsError = (message) => ({
  type: FETCH_BOOK_RIBBONS_ERROR,
  payload: message,
});

export const fetchRibbonBooks = (config) => ({
  type: FETCH_RIBBON_BOOKS,
  payload: config,
});

export const fetchRibbonBooksSuccess = (ribbons) => ({
  type: FETCH_RIBBON_BOOKS_SUCCESS,
  payload: ribbons,
});

export const fetchRibbonBooksError = (message) => ({
  type: FETCH_RIBBON_BOOKS_ERROR,
  payload: message,
});

export const fetchBookDetails = (id) => ({
  type: FETCH_BOOK_DETAILS,
  payload: id,
});

export const fetchBookDetailsSuccess = (ribbons) => ({
  type: FETCH_BOOK_DETAILS_SUCCESS,
  payload: ribbons,
});

export const fetchBookDetailsError = (message) => ({
  type: FETCH_BOOK_DETAILS_ERROR,
  payload: message,
});

export const browseBooks = (params) => ({
  type: BROWSE_BOOKS,
  payload: params,
});

export const browseBooksSuccess = (books) => ({
  type: BROWSE_BOOKS_SUCCESS,
  payload: books,
});

export const browseBooksError = (message) => ({
  type: BROWSE_BOOKS_ERROR,
  payload: message,
});

export const fetchHomeRibbons = () => ({
  type: FETCH_HOME_RIBBONS,
});

export const fetchHomeRibbonsSuccess = (ribbons) => ({
  type: FETCH_HOME_RIBBONS_SUCCESS,
  payload: ribbons,
});

export const fetchHomeRibbonsError = (message) => ({
  type: FETCH_HOME_RIBBONS_ERROR,
  payload: message,
});

export const fetchRelatedBooks = (bookId) => ({
  type: FETCH_RELATED_BOOKS,
  payload: bookId,
});

export const fetchRelatedBooksSuccess = (books) => ({
  type: FETCH_RELATED_BOOKS_SUCCESS,
  payload: books,
});

export const fetchRelatedBooksError = (message) => ({
  type: FETCH_RELATED_BOOKS_ERROR,
  payload: message,
});

export const fetchPreviewRibbons = (data) => ({
  type: FETCH_PREVIEW_RIBBONS,
  payload: data,
});

export const fetchPreviewRibbonsSuccess = (ribbons) => ({
  type: FETCH_PREVIEW_RIBBONS_SUCCESS,
  payload: ribbons,
});

export const fetchPreviewRibbonsError = (message) => ({
  type: FETCH_PREVIEW_RIBBONS_ERROR,
  payload: message,
});
