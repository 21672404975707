import axios from 'axios';
import * as apiURL from './apiConfig';

export const getUpcomingEvents = async () => {
  try {
    const response = await axios.get(apiURL.UPCOMING_EVENTS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getUpcomingEventDetail = async (eventId) => {
  try {
    const response = await axios.get(`${apiURL.EVENT_URL}/${eventId}`);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getPastEvents = async () => {
  try {
    const response = await axios.get(apiURL.PAST_EVENTS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const registerForEvent = async (data) => {
  try {
    const response = await axios.post(apiURL.EVENT_REGISTRATION, data);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
