import {
  TOGGLE_ACCOUNT_SIDEMENU,
  TOGGLE_SOCIAL_MODAL,
} from 'store/actionTypes';

export const toggleAccountSidemenu = (value) => ({
  type: TOGGLE_ACCOUNT_SIDEMENU,
  payload: value,
});

export const toggleSocialModal = (value) => ({
  type: TOGGLE_SOCIAL_MODAL,
  payload: value,
});
