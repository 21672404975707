import { useHistory } from 'react-router-dom';

const CustomToast = ({ buttonText, link, toastText }) => {
  const history = useHistory();

  const handleOnButtonClicked = () => {
    history.push(link);
  };

  return (
    <div className="toastcontent">
      {toastText}
      <div className="actionbtns toastbuttonwrapper">
        <button
          type="button"
          className="wishlist w-100 toastbutton"
          onClick={handleOnButtonClicked}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default CustomToast;
