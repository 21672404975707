import {
  FETCH_DONATED_BOOKS,
  FETCH_DONATED_BOOKS_SUCCESS,
  FETCH_DONATED_BOOKS_ERROR,
} from 'store/actionTypes';

export const fetchDonateBooks = () => ({
  type: FETCH_DONATED_BOOKS,
});

export const fetchDonateBooksSuccess = (data) => ({
  type: FETCH_DONATED_BOOKS_SUCCESS,
  payload: data,
});

export const fetchDonateBooksError = (message) => ({
  type: FETCH_DONATED_BOOKS_ERROR,
  payload: message,
});
