import axios from 'axios';
import * as apiURL from './apiConfig';

export const addOrder = async () => {
  try {
    const response = await axios.post(apiURL.ORDER_URL, {});
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getActiveOrders = async () => {
  try {
    const response = await axios.get(apiURL.ACTIVE_ORDERS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getPickupOrders = async () => {
  try {
    const response = await axios.get(apiURL.PICKUP_ORDERS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getOrderHistory = async ({ page }) => {
  try {
    const response = await axios.get(`${apiURL.ORDER_HISTORY}?page=${page}`);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const applyOrderAction = async (url, data) => {
  try {
    const response = await axios.post(`${apiURL.ORDER_URL}/${url}`, data);
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getUpcomingReturns = async () => {
  try {
    const response = await axios.get(apiURL.UPCOMING_RETURNS);
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
