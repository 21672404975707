import React from 'react';
import { routeConstants } from 'shared/constants';

const ComingSoon = React.lazy(() => import('./'));

const comingSoonRoute = {
  path: routeConstants.COMING_SOON.route,
  component: ComingSoon,
};

export default comingSoonRoute;
