import React from 'react';
import { routeConstants } from 'shared/constants';
const Blogs = React.lazy(() => import('./'));

const blogsRoute = {
  path: routeConstants.BLOGS.route,
  component: Blogs,
  exact: routeConstants.BLOGS.exact,
};

export default blogsRoute;
