const switchCaseError = (props) => {
  let result = {};
  switch (props) {
    case 'viewAll':
      result = {
        path: '/auth/login',
        message: 'Please login/register to view all the books.',
      };
      break;
    case 'cart':
      result = {
        path: '/auth/login',
        message: 'Please login/register to checkout books.',
      };
      break;
    case 'wishList':
      result = {
        path: '/auth/login',
        message: 'Please login/register to checkout books.',
      };
      break;
    default:
      result = {
        path: '/ ',
        message: 'Please login/register to checkout books.',
      };
      break;
  }
  return result;
};
export default switchCaseError;
