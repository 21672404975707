import React from 'react';
import { routeConstants } from 'shared/constants';

const Referrals = React.lazy(() => import('./'));

const referralsRoute = {
  path: routeConstants.ACCOUNT.subRoutes.REFERRALS.route,
  component: Referrals,
};

export default referralsRoute;
