import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  addSubProfile,
  getSubProfiles,
  putSubProfile,
  deleteSubProfile,
} from 'services';
import {
  addSubProfileSuccess,
  addSubProfileError,
  fetchSubProfilesError,
  fetchSubProfilesSuccess,
  editSubProfileSuccess,
  editSubProfileError,
  removeSubProfileSuccess,
  removeSubProfileError,
  fetchUserProfile,
} from 'store/actions';
import {
  ADD_SUB_PROFILE,
  FETCH_SUB_PROFILES,
  EDIT_SUB_PROFILE,
  REMOVE_SUB_PROFILE,
} from 'store/actionTypes';

function* addSubUser({ payload }) {
  try {
    const userObj = yield call(addSubProfile, payload);
    if (!userObj.message) {
      yield put(addSubProfileSuccess(userObj));
      yield put(fetchUserProfile());
    } else {
      yield put(addSubProfileError(userObj.message));
    }
  } catch (error) {
    yield put(addSubProfileError(error));
  }
}

export function* watchAddSubUser() {
  yield takeLatest(ADD_SUB_PROFILE, addSubUser);
}

function* fetchSubUsers() {
  try {
    const userObj = yield call(getSubProfiles);
    if (!userObj.message) {
      yield put(fetchSubProfilesSuccess(userObj));
    } else {
      yield put(fetchSubProfilesError(userObj.message));
    }
  } catch (error) {
    yield put(fetchSubProfilesError(error));
  }
}

export function* watchFetchSubUsers() {
  yield takeLatest(FETCH_SUB_PROFILES, fetchSubUsers);
}

function* editSubUser({ payload }) {
  try {
    const userObj = yield call(putSubProfile, payload);
    if (!userObj.message) {
      yield put(editSubProfileSuccess(userObj));
      yield put(fetchUserProfile());
    } else {
      yield put(editSubProfileError(userObj.message));
    }
  } catch (error) {
    yield put(editSubProfileError(error));
  }
}

export function* watchEditSubUser() {
  yield takeLatest(EDIT_SUB_PROFILE, editSubUser);
}

function* removeSubUser({ payload }) {
  try {
    const userObj = yield call(deleteSubProfile, payload);
    if (!userObj.message) {
      yield put(removeSubProfileSuccess(userObj));
    } else {
      yield put(removeSubProfileError(userObj.message));
    }
  } catch (error) {
    yield put(removeSubProfileError(error));
  }
}

export function* watchRemoveSubUser() {
  yield takeLatest(REMOVE_SUB_PROFILE, removeSubUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddSubUser),
    fork(watchFetchSubUsers),
    fork(watchEditSubUser),
    fork(watchRemoveSubUser),
  ]);
}
