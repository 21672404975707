import React from 'react';
import { routeConstants } from 'shared/constants';
const SubscriptionPlansAd = React.lazy(() => import('./'));

const subscriptionPlansAdRoute = {
  path: routeConstants.SUBSCRIPTION_PLANS_AD.route,
  component: SubscriptionPlansAd,
};

export default subscriptionPlansAdRoute;
