import React from 'react';
import { routeConstants } from 'shared/constants';

const Page404 = React.lazy(() => import('./'));

const page404Route = {
  path: routeConstants.PAGE404.route,
  component: Page404,
};

export default page404Route;
