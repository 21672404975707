import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_ERROR,
  FETCH_CART_ITEMS,
  FETCH_CART_ITEMS_SUCCESS,
  FETCH_CART_ITEMS_ERROR,
  REMOVE_CART_ITEM,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_ERROR,
  FETCH_CART_COUNT,
  FETCH_CART_COUNT_SUCCESS,
  FETCH_CART_COUNT_ERROR,
} from 'store/actionTypes';

export const addCartItem = (bookData) => ({
  type: ADD_CART_ITEM,
  payload: bookData,
});

export const addCartItemSuccess = (data) => ({
  type: ADD_CART_ITEM_SUCCESS,
  payload: data,
});

export const addCartItemError = (message) => ({
  type: ADD_CART_ITEM_ERROR,
  payload: message,
});

export const fetchCartItems = () => ({
  type: FETCH_CART_ITEMS,
});

export const fetchCartItemsSuccess = (data) => ({
  type: FETCH_CART_ITEMS_SUCCESS,
  payload: data,
});

export const fetchCartItemsError = (message) => ({
  type: FETCH_CART_ITEMS_ERROR,
  payload: message,
});

export const removeCartItem = (bookData) => ({
  type: REMOVE_CART_ITEM,
  payload: bookData,
});

export const removeCartItemSuccess = (data) => ({
  type: REMOVE_CART_ITEM_SUCCESS,
  payload: data,
});

export const removeCartItemError = (message) => ({
  type: REMOVE_CART_ITEM_ERROR,
  payload: message,
});

export const fetchCartCount = () => ({
  type: FETCH_CART_COUNT,
});

export const fetchCartCountSuccess = (data) => ({
  type: FETCH_CART_COUNT_SUCCESS,
  payload: data,
});

export const fetchCartCountError = (message) => ({
  type: FETCH_CART_COUNT_ERROR,
  payload: message,
});
