import React from 'react';
import { routeConstants } from 'shared/constants';
const Success = React.lazy(() => import('./'));

const successRoute = {
  path: routeConstants.SUCCESS.route,
  component: Success,
};

export default successRoute;
