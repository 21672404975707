import React from 'react';
import { routeConstants } from 'shared/constants';

const RefundPolicy = React.lazy(() => import('./'));

const refundPolicyRoute = {
  path: routeConstants.REFUND_POLICY.route,
  component: RefundPolicy,
};

export default refundPolicyRoute;
