import axios from 'axios';
import * as apiURL from './apiConfig';

export const addToCart = async (cartData) => {
  try {
    const response = await axios.post(apiURL.CART_URL, {
      ...cartData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getCartItems = async () => {
  try {
    const response = await axios.get(apiURL.CART_URL);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const deleteCartItem = async ({ cartItemId }) => {
  try {
    const response = await axios.delete(`${apiURL.CART_URL}/${cartItemId}`);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getCartItemsCount = async () => {
  try {
    const response = await axios.get(apiURL.CART_COUNT);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
