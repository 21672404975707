import React from 'react';
import { routeConstants } from 'shared/constants';

const PrivacyPolicy = React.lazy(() => import('./'));

const privacyPolicyRoute = {
  path: routeConstants.PRIVACY_POLICY.route,
  component: PrivacyPolicy,
};

export default privacyPolicyRoute;
