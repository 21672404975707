import {
  FETCH_RECOMMENDATIONS,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_ERROR,
} from 'store/actionTypes';

export const fetchRecommendations = (data) => ({
  type: FETCH_RECOMMENDATIONS,
  payload: data,
});

export const fetchRecommendationsSuccess = (data) => ({
  type: FETCH_RECOMMENDATIONS_SUCCESS,
  payload: data,
});

export const fetchRecommendationsError = (message) => ({
  type: FETCH_RECOMMENDATIONS_ERROR,
  payload: message,
});
