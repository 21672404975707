import { useEffect, useState } from 'react';

export const useBorrowedCount = (activeOrders, ordersLoading) => {
  const [ordersFetched, changeFetchStatus] = useState(false);
  const [bookCount, changeBookCount] = useState(0);

  useEffect(() => {
    if (ordersFetched && !ordersLoading) {
      if (activeOrders && activeOrders.length) {
        let totalCount = 0;
        activeOrders.forEach((orderChild) => {
          if (orderChild.books) {
            orderChild.books.forEach(() => {
              totalCount = totalCount + 1;
            });
          }
        });
        changeBookCount(totalCount);
      } else {
        changeBookCount(0);
      }
    }
  }, [activeOrders, ordersFetched, ordersLoading]);

  return [bookCount, changeFetchStatus];
};
