import React from 'react';
import { routeConstants } from 'shared/constants';

const Dashboard = React.lazy(() => import('./'));

const dashboardRoute = {
  path: routeConstants.ACCOUNT.subRoutes.DASHBOARD.route,
  component: Dashboard,
};

export default dashboardRoute;
