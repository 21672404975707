import axios from 'axios';
import * as apiURL from './apiConfig';

export const fetchCities = async ({ config }) => {
  try {
    const response = await axios.get(apiURL.CITY_URL, { ...config });

    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const fetchCommunities = async ({ config }) => {
  try {
    const response = await axios.get(`${apiURL.COMMUNITY_DETAILS_URL}`, {
      ...config,
    });

    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
