import React from 'react';
import routeConstants from 'shared/constants/routes';
const PreSchool = React.lazy(() => import('./'));

const PreSchoolRoute = {
  path: routeConstants.PRESCHOOL.route,
  component: PreSchool,
};

export default PreSchoolRoute;
