const ActionButtons = ({ type, onAction, loading, disableRenewal = true }) => {
  switch (type) {
    case 'checkedOut': {
      return (
        <>
          {!disableRenewal && (
            <button
              className="renew"
              type="button"
              onClick={() => onAction('renew-order')}
            >
              <i className="fa fa-recycle" />
              {loading === 'renew-order' ? 'Loading' : 'Renew'}
            </button>
          )}
          <button
            className="reqpick"
            type="button"
            onClick={() => onAction('pickup-request')}
          >
            <i className="far fa-arrow-alt-circle-down" />
            {loading === 'pickup-request' ? 'Loading' : 'Pickup request'}
          </button>
        </>
      );
    }
    case 'orderPlaced': {
      return (
        <>
          <button
            className="cancpick"
            type="button"
            onClick={() => onAction('cancel-order')}
          >
            <i className="fas fa-exclamation" />
            {loading === 'cancel-order' ? 'Loading' : 'Cancel book'}
          </button>
        </>
      );
    }
    case 'pickupRequested': {
      return (
        <>
          <button
            className="cancpick"
            type="button"
            onClick={() => onAction('cancel-pickup-request')}
          >
            <i className="fas fa-exclamation" />
            {loading === 'cancel-pickup-request' ? 'Loading' : 'Cancel pickup'}
          </button>
        </>
      );
    }
    case 'returnRequest': {
      return (
        <>
          <button
            className="cancpick"
            type="button"
            onClick={() => onAction('lend-return-requests')}
          >
            <i className="fas fa-exclamation" />
            {loading === 'lend-return-requests' ? 'Loading' : 'Return request'}
          </button>
        </>
      );
    }
    default:
      return null;
  }
};

export default ActionButtons;
