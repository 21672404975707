const FormikFileInput = ({ field, setFieldValue }) => {
  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      setFieldValue(field.name, file);
    }
  };

  return (
    <div>
      <input
        id={field.name}
        name={field.name}
        type="file"
        onChange={handleImageChange}
        // onBlur={onBlur}
        //className="form-control"
      />
    </div>
  );
};

export default FormikFileInput;
