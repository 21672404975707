import React from 'react';
import { routeConstants } from 'shared/constants';

const CompleteProfile = React.lazy(() => import('./'));

const completeProfileRoute = {
  path: routeConstants.COMPLETE_PROFILE.route,
  component: CompleteProfile,
  protected: routeConstants.COMPLETE_PROFILE.protected,
};

export default completeProfileRoute;
