import React from 'react';
import { routeConstants } from 'shared/constants';
const AboutUs = React.lazy(() => import('./'));

const aboutUsRoute = {
  path: routeConstants.ABOUT_US.route,
  component: AboutUs,
};

export default aboutUsRoute;
