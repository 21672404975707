import React from 'react';
import { routeConstants } from 'shared/constants';
const BookDetails = React.lazy(() => import('./'));

const bookDetailsRoute = {
  path: routeConstants.BOOK_DETAILS.route,
  component: BookDetails,
};

export default bookDetailsRoute;
