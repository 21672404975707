import { Popover, OverlayTrigger } from 'react-bootstrap';
import maskIcon from 'assets/images/mask.png';
import './HealthNotify.css';

const popover = (
  <Popover id="popover-basic" className="safety-popover">
    <Popover.Title as="h2">
      All books are sanitized with a disinfectant after every use.
    </Popover.Title>
    <Popover.Content>
      Delivery associates have been educated in preventive hygiene measures to
      ensure a safe environment.
    </Popover.Content>
  </Popover>
);

const HealthNotify = () => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="right-end"
      overlay={popover}
      rootClose
    >
      <span className="healthNotify" style={{ height: 40, display: 'block' }}>
        <img src={maskIcon} alt="mask" />
      </span>
    </OverlayTrigger>
  );
};

export default HealthNotify;
