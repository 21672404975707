import axios from 'axios';
import * as apiURL from './apiConfig';

export const postContactUs = async (submitData, type) => {
  try {
    const response = await axios.post(`${apiURL.CONTACTS_URL}/${type}`, {
      ...submitData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
