import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import localStorageService from 'services';
import { routeConstants } from 'shared/constants';
import { toast } from 'react-toastify';

function RouteWithSubRoutes(route) {
  const userData = localStorageService.getUser();

  const handelError = (props) => {
    if (props === '/browse-books') {
      toast.error('Please register to view books.');
    } else if (props === '/cart') {
      toast.error('Please login/register to checkout books.');
    }
  };

  return (
    <Route
      path={route.path}
      exact={!!route.exact}
      render={(props) => {
        if (route.protected) {
          if (userData) {
            return (
              // pass the sub-routes down to keep nesting
              <route.component {...props} routes={route.routes} />
            );
          } else {
            handelError(props.location.pathname);
            return (
              <Redirect
                to={{
                  pathname: routeConstants.AUTH.subRoutes.LOGIN.route,
                  state: { from: props.location },
                }}
              />
            );
          }
        } else if (route.protected === false) {
          if (!userData) {
            return (
              // pass the sub-routes down to keep nesting
              <route.component {...props} routes={route.routes} />
            );
          } else {
            return <Redirect to={routeConstants.HOME.route} />;
          }
        } else {
          return (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes} />
          );
        }
      }}
    />
  );
}

export default RouteWithSubRoutes;
