import axios from 'axios';
import * as apiURL from './apiConfig';

export const postAmbassadorRequest = async (submitData) => {
  try {
    let formData = new FormData();
    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }
    const response = await axios.post(`${apiURL.AMBASSADOR_URL}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
