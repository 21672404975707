import React from 'react';
import { routeConstants } from 'shared/constants';
const SearchedBooks = React.lazy(() => import('./'));

const searchedBooksRoute = {
  path: routeConstants.SEARCHED_BOOKS.route,
  component: SearchedBooks,
};

export default searchedBooksRoute;
