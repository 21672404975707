import React from 'react';
import { routeConstants } from 'shared/constants';
import dashboardRoute from './screens/Dashboard/route';
import myAccountRoute from './screens/MyAccount/route';
import wishlistRoute from './screens/Wishlist/route';
import borrowedBooksRoute from './screens/BorrowedBooks/route';
import donatedBooksRoute from './screens/DonatedBooks/route';
import finesRoute from './screens/Fines/route';
import referralsRoute from './screens/Referrals/route';
const Account = React.lazy(() => import('./'));

const accountRoute = {
  path: routeConstants.ACCOUNT.route,
  component: Account,
  protected: routeConstants.ACCOUNT.protected,
  routes: [
    dashboardRoute,
    myAccountRoute,
    wishlistRoute,
    borrowedBooksRoute,
    donatedBooksRoute,
    finesRoute,
    referralsRoute,
  ],
};

export default accountRoute;
