import { BookSliderItem } from 'shared/components';

const BookList = ({ bookItems, searchResults }) => {
  const { title, books } = bookItems;

  return (
    <div className="body-container">
      {title === undefined ? null : (
        <div className="zoneheadingbox">
          <div className="sectionheader">
            {searchResults ? 'Your Search Results :' : `Books : ${title}`}
          </div>
        </div>
      )}
      <div className="aftersearchlist">
        {books && Array.isArray(books) && books.length ? (
          books.map((bookItem) => {
            return <BookSliderItem bookData={bookItem} key={bookItem.bookId} />;
          })
        ) : (
          <h5 className="no-message mt-1 ">
            There are currently 0 results for your search.
          </h5>
        )}
      </div>
    </div>
  );
};

export default BookList;
