import { useEffect, useState } from 'react';

export const useSubmitResponse = ({ error, loading, callback }) => {
  const [valueSubmitted, changeSubmitStatus] = useState(false);

  useEffect(() => {
    if (valueSubmitted && !loading && !error) {
      changeSubmitStatus(false);
      callback();
    }
  }, [valueSubmitted, loading, error, callback]);

  return [changeSubmitStatus];
};
