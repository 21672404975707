import {
  FETCH_DONATED_BOOKS,
  FETCH_DONATED_BOOKS_ERROR,
  FETCH_DONATED_BOOKS_SUCCESS,
} from 'store/actionTypes';

const INIT_STATE = {
  error: '',
  donatedBooks: [],
  donatedBooksLoading: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DONATED_BOOKS:
      return {
        ...state,
        donatedBooksLoading: true,
        error: '',
      };
    case FETCH_DONATED_BOOKS_SUCCESS:
      return {
        ...state,
        donatedBooksLoading: false,
        donatedBooks: [...action.payload],
        error: '',
      };
    case FETCH_DONATED_BOOKS_ERROR:
      return {
        ...state,
        donatedBooksLoading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
