export default Object.freeze({
  HOME: {
    name: 'Home',
    route: '/ ',
  },
  ERROR: {
    name: 'Error',
    route: '/error',
  },
  PAGE404: {
    name: '404',
    route: '/404',
  },
  AUTH: {
    name: 'Auth',
    route: '/auth',
    protected: false,
    subRoutes: {
      REGISTRATION: {
        name: 'Registration',
        route: '/auth/registration',
      },
      LOGIN: {
        name: 'Login',
        route: '/auth/login',
      },
    },
  },
  ACCOUNT: {
    name: 'Account',
    route: '/account',
    protected: true,
    subRoutes: {
      DASHBOARD: {
        name: 'Dashboard',
        route: '/account/dashboard',
      },
      MY_ACCOUNT: {
        name: 'My Account',
        route: '/account/my-account',
      },
      FINES: {
        name: 'Fines',
        route: '/account/fines',
      },
      WISHLIST: {
        name: 'Wishlist',
        route: '/account/wishlist',
      },
      DONATED_BOOKS: {
        name: 'Donated Books',
        route: '/account/my-donations',
      },
      BORROWED_BOOKS: {
        name: 'Borrowed Books',
        route: '/account/borrowed-books',
      },
      REFERRALS: {
        name: 'Referrals',
        route: '/account/my-referrals',
      },
    },
  },
  COMPLETE_PROFILE: {
    name: 'Complete Profile',
    route: '/complete-profile',
    protected: true,
  },
  BOOK_DETAILS: {
    name: 'Book Details',
    route: '/book-details/:id',
  },
  BROWSE_BOOKS: {
    name: 'Browse Books',
    route: '/browse-books',
    exact: true,
    protected: true,
  },
  BROWSE_RIBBON_BOOKS: {
    name: 'Browse Ribbon Books',
    route: '/browse-books/ribbon/:key',
    exact: true,
  },
  SEARCHED_BOOKS: {
    name: 'Searched Books',
    route: '/searched-books',
  },
  PRESCHOOL: {
    name: 'Pre school',
    route: '/preschool',
  },
  CART: {
    name: 'Cart',
    route: '/cart',
    protected: true,
  },
  CHECKOUT: {
    name: 'Checkout',
    route: '/checkout',
  },
  SUBSCRIPTION_PLANS: {
    name: 'Subscription Plans',
    route: '/subscriptionplans',
  },
  SUBSCRIPTION_PLANS_AD: {
    name: 'Subscription Plans',
    route: '/ad-subscriptionplans',
  },
  CONTACT_US: {
    name: 'Contact Us',
    route: '/contact-us',
  },
  REQUEST_COMMUNITY: {
    name: 'Request Community',
    route: '/request-community',
  },
  FAQ: {
    name: 'FAQ',
    route: '/faq',
  },
  SUCCESS: {
    name: 'Success',
    route: '/success',
  },
  COMING_SOON: {
    name: 'Coming Soon',
    route: '/coming-soon',
  },
  TERMS: {
    name: 'Terms and conditions',
    route: '/terms',
  },
  REFUND_POLICY: {
    name: 'Refund Policy',
    route: '/refund-policy',
  },
  PRIVACY_POLICY: {
    name: 'Privacy Policy',
    route: '/privacy-policy',
  },
  DELIVERY_POLICY: {
    name: 'Delivery Policy',
    route: '/delivery-policy',
  },
  BLOGS: {
    name: 'Blogs',
    route: '/blogs',
    exact: true,
  },
  BLOG_DETAILS: {
    name: 'Blog Details',
    route: '/blogs/:id',
    exact: true,
  },
  ABOUT_US: {
    name: 'About Us',
    route: '/about-us',
  },
  RESET_PASSWORD: {
    name: 'Reset Password',
    route: '/reset-password',
  },
  RECOMMENDATIONS: {
    name: 'Recommendations',
    route: '/recommendations',
  },
  AMBASSADOR: {
    name: 'Ambassador',
    route: '/ambassador',
    exact: true,
  },
  AMBASSADOR_APPLY: {
    name: 'Ambassador',
    route: '/ambassador/apply',
    exact: true,
  },
  EVENTS: {
    name: 'Events',
    route: '/events',
    exact: true,
  },
  EVENTS_REGISTER: {
    name: 'Events',
    route: '/events/:eventId/register',
    exact: true,
  },
  DONOR_APPLY: {
    name: 'Donor Apply',
    route: '/donor-apply',
  },
  LIBRARY_PREVIEW: {
    name: 'Library Preview',
    route: '/library-preview/:communityId',
  },
});
