import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  plans: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
        plans: [],
        error: '',
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: [...action.payload],
        error: '',
      };
    case FETCH_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: false,
        plans: [],
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
