import { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import defaultImage from 'assets/images/indian1.jpg';
import { imageConfigs } from 'shared/constants';
import { Tooltip, Overlay } from 'react-bootstrap';
import moment from 'moment';
import {
  ConfirmationModal,
  ImageWithLoading,
  ActionButtons,
} from 'shared/components';
import { applyOrderAction, postUserRequest } from 'services';
import {
  fetchActiveOrders,
  fetchDonateBooks,
  fetchOrderHistory,
  fetchPickupOrders,
  fetchUserProfile,
} from 'store/actions';
import { toast } from 'react-toastify';
import { useOutsideAlerter } from 'shared/hooks';

const { IMAGE_URL } = imageConfigs;

const DashboardSliderItem = ({ bookData, orderHistory, donatedBook }) => {
  const dispatch = useDispatch();
  const [loading, changeLoadingStatus] = useState('');
  const [showConfirm, changeConfirm] = useState(null);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const wrapperRef = useRef(null);

  const onClickOutside = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useOutsideAlerter(wrapperRef, onClickOutside);

  const {
    bookCover,
    deliveryStatus,
    _id,
    purpose,
    returnStatus,
    book,
    fined,
    bookTitle,
    returnDate,
    deliveryDate,
    renewalDate,
    bookAuthors,
    finesPaid,
    manualFine,
  } = bookData;
  const imageUrl = bookCover ? `${IMAGE_URL}/${bookCover}` : defaultImage;

  const handleOnActionClicked = async (url) => {
    console.log('url', url);
    changeLoadingStatus(url);
    let apiService;
    let postData;
    if (url === 'lend-return-requests') {
      apiService = postUserRequest;
      postData = { book };
    } else {
      apiService = applyOrderAction;
      postData = { orderId: _id };
    }
    try {
      const userObj = await apiService(url, postData);
      changeLoadingStatus('');
      if (!userObj.error) {
        dispatch(fetchActiveOrders());
        dispatch(fetchPickupOrders());
        dispatch(fetchOrderHistory());
        dispatch(fetchUserProfile());
        dispatch(fetchDonateBooks());
        toast.success(userObj.data.message || 'Action completed');
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      // toast.error('Something went wrong');
      changeLoadingStatus('');
    }
    changeConfirm(null);
  };

  const handleToggleConfirm = (value = null) => {
    console.log('value', value);
    if (value) {
      let data = { url: '', text: '' };
      switch (value) {
        case 'pickup-request':
          data = { url: value, text: 'Request pickup' };
          break;
        case 'renew-order':
          data = { url: value, text: 'Renew Book' };
          break;
        case 'cancel-order':
          data = { url: value, text: 'Cancel book' };
          break;
        case 'cancel-pickup-request':
          data = { url: value, text: 'Cancel pickup' };
          break;
        case 'lend-return-requests':
          data = { url: value, text: 'Request return' };
          break;
        default:
          return data;
      }
      changeConfirm(data);
    } else {
      changeConfirm(value);
    }
  };

  return (
    <div className="slide" ref={wrapperRef}>
      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props} className="orders-tooltip">
            <div className="info-order">
              <ul>
                <li>
                  <b>Title : </b>
                  {bookTitle}
                </li>
                <li>
                  <b>Author(s) : </b>
                  {bookAuthors.map((author) => author.name).join(', ')}
                </li>
                <li>
                  <b>Issued date : </b>
                  {moment(deliveryDate).format('Do MMM, YYYY')}
                </li>
                {renewalDate && (
                  <li>
                    <b>Renewal date : </b>
                    {moment(renewalDate).format('Do MMM, YYYY')}
                  </li>
                )}
                {returnDate && (
                  <li>
                    <b>Due date : </b>
                    {moment(returnDate).format('Do MMM, YYYY')}
                  </li>
                )}
              </ul>
            </div>
          </Tooltip>
        )}
      </Overlay>
      <div className="orderinfo">
        <i
          className="fas fa-info-circle"
          /*data-tip={true}
          data-for="nominate-check"*/
          ref={target}
          onClick={() => setShow(true)}
        />
      </div>
      <div className="deachbook">
        <ConfirmationModal
          show={!!showConfirm}
          onConfirm={() =>
            handleOnActionClicked(showConfirm && showConfirm.url)
          }
          handleClose={() => handleToggleConfirm()}
          hideBody
          conFirmButtonText={showConfirm && showConfirm.text}
        />
        <ImageWithLoading
          alt="Indian"
          src={imageUrl}
          className="img-responsive"
        />
        {/*<div className="isfined">Fine</div>*/}
        {deliveryStatus === 'checkedOut' ? (
          fined ? (
            <div className="isfined" style={{ backgroundColor: 'red' }}>
              Overdue
            </div>
          ) : !renewalDate ? (
            <div className="isfined" style={{ backgroundColor: 'green' }}>
              Delivered
            </div>
          ) : (
            <div className="isfined" style={{ backgroundColor: 'green' }}>
              Renewed
            </div>
          )
        ) : null}
        {deliveryStatus === 'pickupRequested' && fined ? (
          finesPaid ? (
            <div className="isfined" style={{ backgroundColor: 'green' }}>
              Paid
            </div>
          ) : (
            <div className="isfined" style={{ backgroundColor: 'red' }}>
              Fine
            </div>
          )
        ) : null}
        {deliveryStatus === 'checkedIn' && (manualFine || fined) ? (
          finesPaid ? (
            <div className="isfined" style={{ backgroundColor: 'green' }}>
              Paid
            </div>
          ) : (
            <div className="isfined" style={{ backgroundColor: 'red' }}>
              Fine
            </div>
          )
        ) : null}
        {donatedBook && returnStatus === 'requested' ? (
          <div className="isfined" style={{ backgroundColor: 'green' }}>
            Requested
          </div>
        ) : null}
        {orderHistory ? null : (
          <div className="overhover">
            <ActionButtons
              disableRenewal={!!renewalDate || !!fined}
              type={deliveryStatus}
              loading={loading}
              onAction={handleToggleConfirm}
            />
            {/*(
            <button className="review" type="button">
              <i className="fa fa-star" />
              Review
            </button>
          )*/}
          </div>
        )}
        {donatedBook && returnStatus === null && purpose === 'lend' && (
          <div className="overhover">
            <ActionButtons
              type="returnRequest"
              loading={loading}
              onAction={handleToggleConfirm}
            />
            {/*(
            <button className="review" type="button">
              <i className="fa fa-star" />
              Review
            </button>
          )*/}
          </div>
        )}
      </div>
    </div>
  );
};
export default DashboardSliderItem;
