import {
  ADD_SUB_PROFILE,
  ADD_SUB_PROFILE_ERROR,
  ADD_SUB_PROFILE_SUCCESS,
  FETCH_SUB_PROFILES,
  FETCH_SUB_PROFILES_ERROR,
  FETCH_SUB_PROFILES_SUCCESS,
  EDIT_SUB_PROFILE,
  EDIT_SUB_PROFILE_ERROR,
  EDIT_SUB_PROFILE_SUCCESS,
  REMOVE_SUB_PROFILE,
  REMOVE_SUB_PROFILE_ERROR,
  REMOVE_SUB_PROFILE_SUCCESS,
} from '../actionTypes';

export const addSubProfile = (data) => ({
  type: ADD_SUB_PROFILE,
  payload: data,
});

export const addSubProfileSuccess = (user) => ({
  type: ADD_SUB_PROFILE_SUCCESS,
  payload: user,
});

export const addSubProfileError = (message) => ({
  type: ADD_SUB_PROFILE_ERROR,
  payload: message,
});

export const fetchSubProfiles = (data) => ({
  type: FETCH_SUB_PROFILES,
  payload: data,
});

export const fetchSubProfilesSuccess = (users) => ({
  type: FETCH_SUB_PROFILES_SUCCESS,
  payload: users,
});

export const fetchSubProfilesError = (message) => ({
  type: FETCH_SUB_PROFILES_ERROR,
  payload: message,
});

export const editSubProfile = (data) => ({
  type: EDIT_SUB_PROFILE,
  payload: data,
});

export const editSubProfileSuccess = (user) => ({
  type: EDIT_SUB_PROFILE_SUCCESS,
  payload: user,
});

export const editSubProfileError = (message) => ({
  type: EDIT_SUB_PROFILE_ERROR,
  payload: message,
});

export const removeSubProfile = (data) => ({
  type: REMOVE_SUB_PROFILE,
  payload: data,
});

export const removeSubProfileSuccess = (user) => ({
  type: REMOVE_SUB_PROFILE_SUCCESS,
  payload: user,
});

export const removeSubProfileError = (message) => ({
  type: REMOVE_SUB_PROFILE_ERROR,
  payload: message,
});
