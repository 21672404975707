import { Modal } from 'react-bootstrap';

const ConfirmationModal = ({
  show,
  handleClose,
  onConfirm,
  bodyText = 'This action is irreversible!',
  conFirmButtonText = 'Delete',
  cancelButtonText = 'Close',
  titleText = 'Are you sure?',
  hideBody = false,
  hideFooter = false,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      centered
      backdropClassName="confirm-backdrop"
      style={{ zIndex: 10500 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{titleText}</Modal.Title>
      </Modal.Header>
      {!hideBody && <Modal.Body>{bodyText}</Modal.Body>}
      <Modal.Footer>
        {!hideFooter && (
          <div className="actionbtns m-0">
            <button
              type="button"
              className="remove mr-2 my-0"
              onClick={handleClose}
            >
              {cancelButtonText}
            </button>
            <button type="button" className="wishlist my-0" onClick={onConfirm}>
              {conFirmButtonText}
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
