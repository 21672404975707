const TooloButton = ({
  loading,
  buttonText,
  loadingText,
  type,
  disabled,
  className = 'rlc-submit hvr-outline-out',
  ...restProps
}) => {
  return (
    <button
      type={type}
      className={className}
      disabled={disabled || loading}
      {...restProps}
    >
      {loading ? loadingText : buttonText}
    </button>
  );
};

export default TooloButton;
