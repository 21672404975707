import React from 'react';
import routeConstants from 'shared/constants/routes';
const Events = React.lazy(() => import('./'));

const eventRoute = {
  path: routeConstants.EVENTS.route,
  exact: routeConstants.EVENTS.exact,
  component: Events,
};

export default eventRoute;
