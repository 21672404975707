import axios from 'axios';
import * as apiURL from './apiConfig';

export const updateUser = async (profileData) => {
  try {
    const response = await axios.put(apiURL.USER_URL, { ...profileData });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getUserProfile = async () => {
  try {
    const response = await axios.get(apiURL.USER_PROFILE);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const postUserRequest = async (param, data) => {
  try {
    const response = await axios.post(apiURL.USER_REQUESTS, data);
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
