import React from 'react';
import { routeConstants } from 'shared/constants';

const Terms = React.lazy(() => import('./'));

const termsRoute = {
  path: routeConstants.TERMS.route,
  component: Terms,
};

export default termsRoute;
