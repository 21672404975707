const LoadMore = ({ onLoadMore, buttonName }) => {
  return (
    <div className="w-100 text-center">
      <button onClick={onLoadMore} className="wishlist mt-5">
        {buttonName}
      </button>
    </div>
  );
};

export default LoadMore;
