import React from 'react';
import { routeConstants } from 'shared/constants';
const CommunityRequest = React.lazy(() => import('./'));

const communityRequestRoute = {
  path: routeConstants.REQUEST_COMMUNITY.route,
  component: CommunityRequest,
};

export default communityRequestRoute;
