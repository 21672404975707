import HomeRoute from './screens/Home/route';
import AuthRoute from './screens/Auth/route';
import AccountRoute from './screens/Account/route';
import CompleteProfileRoute from './screens/CompleteProfile/route';
import BookDetailsRoute from './screens/BookDetails/route';
import BrowseBooksRoute from './screens/BrowseBooks/route';
import BrowseRibbonBooksRoute from './screens/BrowseRibbonBooks/route';
import SearchedBooksRoute from './screens/SearchedBooks/route';
import CartRoute from './screens/Cart/route';
import CheckoutRoute from './screens/Checkout/route';
import SubscriptionPlansRoute from './screens/SubscriptionPlans/route';
import ContactUsRoute from './screens/ContactUs/route';
import CommunityRequestRoute from './screens/CommunityRequest/route';
import FaqRoute from './screens/Faq/route';
import SuccessRoute from './screens/Success/route';
import ComingSoonRoute from './screens/ComingSoon/route';
import TermsRoute from './screens/Terms/route';
import PrivacyPolicyRoute from './screens/PrivacyPolicy/route';
import RefundPolicyRoute from './screens/RefundPolicy/route';
import DeliveryPolicyRoute from './screens/DeliveryPolicy/route';
import BlogsRoute from './screens/Blogs/route';
import AboutUsRoute from './screens/AboutUs/route';
import ResetPasswordRoute from './screens/ResetPassword/route';
import RecommendationsRoute from './screens/Recommendations/route';
import BlogDetailsRoute from './screens/BlogDetails/route';
import AmbassadorRoute from './screens/Ambassador/route';
import AmbassadorApplyRoute from './screens/AmbassadorApply/route';
import EventsRoute from './screens/Events/route';
import EventsRegisterRoute from './screens/EventsRegister/route';
import DonorApplyRoute from './screens/DonorApply/route';
import LibraryPreviewRoute from './screens/LibraryPreview/route';
import subscriptionPlansAdRoute from './screens/SubscriptionPlansAd/route';
import PreSchoolRoute from './screens/PreSchool/route';

const screenRoutes = [
  HomeRoute,
  AuthRoute,
  AccountRoute,
  CompleteProfileRoute,
  BookDetailsRoute,
  BrowseBooksRoute,
  BrowseRibbonBooksRoute,
  SearchedBooksRoute,
  CartRoute,
  CheckoutRoute,
  SubscriptionPlansRoute,
  ContactUsRoute,
  CommunityRequestRoute,
  FaqRoute,
  SuccessRoute,
  ComingSoonRoute,
  TermsRoute,
  PrivacyPolicyRoute,
  RefundPolicyRoute,
  DeliveryPolicyRoute,
  BlogsRoute,
  AboutUsRoute,
  ResetPasswordRoute,
  RecommendationsRoute,
  BlogDetailsRoute,
  AmbassadorRoute,
  AmbassadorApplyRoute,
  EventsRoute,
  EventsRegisterRoute,
  DonorApplyRoute,
  LibraryPreviewRoute,
  subscriptionPlansAdRoute,
  PreSchoolRoute,
];

export default screenRoutes;
