import {
  ADD_WISHLIST_ITEM,
  ADD_WISHLIST_ITEM_SUCCESS,
  ADD_WISHLIST_ITEM_ERROR,
  FETCH_WISHLIST_ITEMS,
  FETCH_WISHLIST_ITEMS_SUCCESS,
  FETCH_WISHLIST_ITEMS_ERROR,
  REMOVE_WISHLIST_ITEM,
  REMOVE_WISHLIST_ITEM_SUCCESS,
  REMOVE_WISHLIST_ITEM_ERROR,
} from 'store/actionTypes';

export const addWishlistItem = (wishlistData) => ({
  type: ADD_WISHLIST_ITEM,
  payload: wishlistData,
});

export const addWishlistItemSuccess = (data) => ({
  type: ADD_WISHLIST_ITEM_SUCCESS,
  payload: data,
});

export const addWishlistItemError = (message) => ({
  type: ADD_WISHLIST_ITEM_ERROR,
  payload: message,
});

export const fetchWishlistItems = () => ({
  type: FETCH_WISHLIST_ITEMS,
});

export const fetchWishlistItemsSuccess = (data) => ({
  type: FETCH_WISHLIST_ITEMS_SUCCESS,
  payload: data,
});

export const fetchWishlistItemsError = (message) => ({
  type: FETCH_WISHLIST_ITEMS_ERROR,
  payload: message,
});

export const removeWishlistItem = (wishlistData) => ({
  type: REMOVE_WISHLIST_ITEM,
  payload: wishlistData,
});

export const removeWishlistItemSuccess = (data) => ({
  type: REMOVE_WISHLIST_ITEM_SUCCESS,
  payload: data,
});

export const removeWishlistItemError = (message) => ({
  type: REMOVE_WISHLIST_ITEM_ERROR,
  payload: message,
});
