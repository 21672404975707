import axios from 'axios';
import * as apiURL from './apiConfig';

export const addSubProfile = async (profileData) => {
  try {
    const response = await axios.post(apiURL.SUB_PROFILE_URL, {
      ...profileData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getSubProfiles = async () => {
  try {
    const response = await axios.get(apiURL.SUB_PROFILE_URL);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const putSubProfile = async (profileData) => {
  const newData = { ...profileData };
  const id = newData._id;
  delete newData._id;
  try {
    const response = await axios.put(`${apiURL.SUB_PROFILE_URL}/${id}`, {
      ...newData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const deleteSubProfile = async (data) => {
  try {
    const response = await axios.delete(
      `${apiURL.SUB_PROFILE_URL}/${data._id}`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
