import axios from 'axios';
import * as apiURL from './apiConfig';

export const fetchGenres = async ({ config }) => {
  try {
    const response = await axios.get(apiURL.GENRE_URL, { ...config });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
