//auth
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_ERROR = 'USER_REGISTRATION_ERROR';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_ERROR = 'GOOGLE_LOGIN_ERROR';
export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN';
export const FACEBOOK_LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_ERROR = 'FACEBOOK_LOGIN_ERROR';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const CHECK_AUTH = 'CHECK_AUTH';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const RESET_LOGOUT_REDIRECTION = 'RESET_LOGOUT_REDIRECTION';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const FETCH_USER_IMAGES = 'FETCH_USER_IMAGES';
export const FETCH_USER_IMAGES_SUCCESS = 'FETCH_USER_IMAGES_SUCCESS';
export const FETCH_USER_IMAGES_ERROR = 'FETCH_USER_IMAGES_ERROR';

//sub profile
export const ADD_SUB_PROFILE = 'ADD_SUB_PROFILE';
export const ADD_SUB_PROFILE_SUCCESS = 'ADD_SUB_PROFILE_SUCCESS';
export const ADD_SUB_PROFILE_ERROR = 'ADD_SUB_PROFILE_ERROR';
export const FETCH_SUB_PROFILES = 'FETCH_SUB_PROFILES';
export const FETCH_SUB_PROFILES_SUCCESS = 'FETCH_SUB_PROFILES_SUCCESS';
export const FETCH_SUB_PROFILES_ERROR = 'FETCH_SUB_PROFILES_ERROR';
export const EDIT_SUB_PROFILE = 'EDIT_SUB_PROFILE';
export const EDIT_SUB_PROFILE_SUCCESS = 'EDIT_SUB_PROFILE_SUCCESS';
export const EDIT_SUB_PROFILE_ERROR = 'EDIT_SUB_PROFILE_ERROR';
export const REMOVE_SUB_PROFILE = 'REMOVE_SUB_PROFILE';
export const REMOVE_SUB_PROFILE_SUCCESS = 'REMOVE_SUB_PROFILE_SUCCESS';
export const REMOVE_SUB_PROFILE_ERROR = 'REMOVE_SUB_PROFILE_ERROR';

//books
export const FETCH_BOOK_RIBBONS = 'FETCH_BOOK_RIBBONS';
export const FETCH_BOOK_RIBBONS_SUCCESS = 'FETCH_BOOK_RIBBONS_SUCCESS';
export const FETCH_BOOK_RIBBONS_ERROR = 'FETCH_BOOK_RIBBONS_ERROR';
export const FETCH_RIBBON_BOOKS = 'FETCH_RIBBON_BOOKS';
export const FETCH_RIBBON_BOOKS_SUCCESS = 'FETCH_RIBBON_BOOKS_SUCCESS';
export const FETCH_RIBBON_BOOKS_ERROR = 'FETCH_RIBBON_BOOKS_ERROR';
export const FETCH_BOOK_DETAILS = 'FETCH_BOOK_DETAILS';
export const FETCH_BOOK_DETAILS_SUCCESS = 'FETCH_BOOK_DETAILS_SUCCESS';
export const FETCH_BOOK_DETAILS_ERROR = 'FETCH_BOOK_DETAILS_ERROR';
export const BROWSE_BOOKS = 'BROWSE_BOOKS';
export const BROWSE_BOOKS_SUCCESS = 'BROWSE_BOOKS_SUCCESS';
export const BROWSE_BOOKS_ERROR = 'BROWSE_BOOKS_ERROR';
export const FETCH_HOME_RIBBONS = 'FETCH_HOME_RIBBONS';
export const FETCH_HOME_RIBBONS_SUCCESS = 'FETCH_HOME_RIBBONS_SUCCESS';
export const FETCH_HOME_RIBBONS_ERROR = 'FETCH_HOME_RIBBONS_ERROR';
export const FETCH_RELATED_BOOKS = 'FETCH_RELATED_BOOKS';
export const FETCH_RELATED_BOOKS_SUCCESS = 'FETCH_RELATED_BOOKS_SUCCESS';
export const FETCH_RELATED_BOOKS_ERROR = 'FETCH_RELATED_BOOKS_ERROR';

//cart
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const ADD_CART_ITEM_SUCCESS = 'ADD_CART_ITEM_SUCCESS';
export const ADD_CART_ITEM_ERROR = 'ADD_CART_ITEM_ERROR';
export const FETCH_CART_ITEMS = 'FETCH_CART_ITEMS';
export const FETCH_CART_ITEMS_SUCCESS = 'FETCH_CART_ITEMS_SUCCESS';
export const FETCH_CART_ITEMS_ERROR = 'FETCH_CART_ITEMS_ERROR';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_ERROR = 'REMOVE_CART_ITEM_ERROR';
export const FETCH_CART_COUNT = 'FETCH_CART_COUNT';
export const FETCH_CART_COUNT_SUCCESS = 'FETCH_CART_COUNT_SUCCESS';
export const FETCH_CART_COUNT_ERROR = 'FETCH_CART_COUNT_ERROR';

//wishlist
export const ADD_WISHLIST_ITEM = 'ADD_WISHLIST_ITEM';
export const ADD_WISHLIST_ITEM_SUCCESS = 'ADD_WISHLIST_ITEM_SUCCESS';
export const ADD_WISHLIST_ITEM_ERROR = 'ADD_WISHLIST_ITEM_ERROR';
export const FETCH_WISHLIST_ITEMS = 'FETCH_WISHLIST_ITEMS';
export const FETCH_WISHLIST_ITEMS_SUCCESS = 'FETCH_WISHLIST_ITEMS_SUCCESS';
export const FETCH_WISHLIST_ITEMS_ERROR = 'FETCH_WISHLIST_ITEMS_ERROR';
export const REMOVE_WISHLIST_ITEM = 'REMOVE_WISHLIST_ITEM';
export const REMOVE_WISHLIST_ITEM_SUCCESS = 'REMOVE_WISHLIST_ITEM_SUCCESS';
export const REMOVE_WISHLIST_ITEM_ERROR = 'REMOVE_WISHLIST_ITEM_ERROR';

//orders
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_ERROR = 'ADD_ORDER_ERROR';
export const FETCH_ACTIVE_ORDERS = 'FETCH_ACTIVE_ORDERS';
export const FETCH_ACTIVE_ORDERS_SUCCESS = 'FETCH_ACTIVE_ORDERS_SUCCESS';
export const FETCH_ACTIVE_ORDERS_ERROR = 'FETCH_ACTIVE_ORDERS_ERROR';
export const FETCH_PICKUP_ORDERS = 'FETCH_PICKUP_ORDERS';
export const FETCH_PICKUP_ORDERS_SUCCESS = 'FETCH_PICKUP_ORDERS_SUCCESS';
export const FETCH_PICKUP_ORDERS_ERROR = 'FETCH_PICKUP_ORDERS_ERROR';
export const FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY';
export const FETCH_ORDER_HISTORY_SUCCESS = 'FETCH_ORDER_HISTORY_SUCCESS';
export const FETCH_ORDER_HISTORY_ERROR = 'FETCH_ORDER_HISTORY_ERROR';

//Donated Books
export const FETCH_DONATED_BOOKS = 'FETCH_DONATED_BOOKS';
export const FETCH_DONATED_BOOKS_SUCCESS = 'FETCH_DONATED_BOOKS_SUCCESS';
export const FETCH_DONATED_BOOKS_ERROR = 'FETCH_DONATED_BOOKS_ERROR';

//subscriptions
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR';

//Reviews
export const FETCH_BOOK_REVIEWS = 'FETCH_BOOK_REVIEWS';
export const FETCH_BOOK_REVIEWS_SUCCESS = 'FETCH_BOOK_REVIEWS_SUCCESS';
export const FETCH_BOOK_REVIEWS_ERROR = 'FETCH_BOOK_REVIEWS_ERROR';

//FAQs
export const FETCH_FAQS = 'FETCH_FAQS';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_ERROR = 'FETCH_FAQS_ERROR';
export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';
export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS';
export const FETCH_TESTIMONIALS_ERROR = 'FETCH_TESTIMONIALS_ERROR';

//Blogs
export const FETCH_BLOGS = 'FETCH_BLOGS';
export const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';
export const FETCH_BLOGS_ERROR = 'FETCH_BLOGS_ERROR';
export const FETCH_BLOG_DETAILS = 'FETCH_BLOG_DETAILS';
export const FETCH_BLOG_DETAILS_SUCCESS = 'FETCH_BLOG_DETAILS_SUCCESS';
export const FETCH_BLOG_DETAILS_ERROR = 'FETCH_BLOG_DETAILS_ERROR';

//Recommendations
export const FETCH_RECOMMENDATIONS = 'FETCH_RECOMMENDATIONS';
export const FETCH_RECOMMENDATIONS_SUCCESS = 'FETCH_RECOMMENDATIONS_SUCCESS';
export const FETCH_RECOMMENDATIONS_ERROR = 'FETCH_RECOMMENDATIONS_ERROR';

//Configs
export const TOGGLE_ACCOUNT_SIDEMENU = 'TOGGLE_ACCOUNT_SIDEMENU';
export const TOGGLE_SOCIAL_MODAL = 'TOGGLE_SOCIAL_MODAL';

//Events
export const FETCH_UPCOMING_EVENTS = 'FETCH_UPCOMING_EVENTS';
export const FETCH_UPCOMING_EVENTS_SUCCESS = 'FETCH_UPCOMING_EVENTS_SUCCESS';
export const FETCH_UPCOMING_EVENTS_ERROR = 'FETCH_UPCOMING_EVENTS_ERROR';
export const FETCH_UPCOMING_EVENT_DETAIL = 'FETCH_UPCOMING_EVENT_DETAIL';
export const FETCH_UPCOMING_EVENT_DETAIL_SUCCESS =
  'FETCH_UPCOMING_EVENT_DETAIL_SUCCESS';
export const FETCH_UPCOMING_EVENT_DETAIL_ERROR =
  'FETCH_UPCOMING_EVENT_DETAIL_ERROR';
export const FETCH_PAST_EVENTS = 'FETCH_PAST_EVENTS';
export const FETCH_PAST_EVENTS_SUCCESS = 'FETCH_PAST_EVENTS_SUCCESS';
export const FETCH_PAST_EVENTS_ERROR = 'FETCH_PAST_EVENTS_ERROR';

//Fines
export const FETCH_FINES = 'FETCH_FINES';
export const FETCH_FINES_SUCCESS = 'FETCH_FINES_SUCCESS';
export const FETCH_FINES_ERROR = 'FETCH_FINES_ERROR';

// PREVIEW RIBBONS
export const FETCH_PREVIEW_RIBBONS = 'FETCH_PREVIEW_RIBBONS';
export const FETCH_PREVIEW_RIBBONS_SUCCESS = 'FETCH_PREVIEW_RIBBONS_SUCCESS';
export const FETCH_PREVIEW_RIBBONS_ERROR = 'FETCH_PREVIEW_RIBBONS_ERROR';

// MY REFERRALS
export const FETCH_MY_REFERRALS = 'FETCH_MY_REFERRALS';
export const FETCH_MY_REFERRALS_SUCCESS = 'FETCH_MY_REFERRALS_SUCCESS';
export const FETCH_MY_REFERRALS_ERROR = 'FETCH_MY_REFERRALS_ERROR';

// REFERRAL STATS
export const FETCH_REFERRAL_STATS = 'FETCH_REFERRAL_STATS';
export const FETCH_REFERRAL_STATS_SUCCESS = 'FETCH_REFERRAL_STATS_SUCCESS';
export const FETCH_REFERRAL_STATS_ERROR = 'FETCH_REFERRAL_STATS_ERROR';
export const REQUEST_REDEEM_REFERRALS = 'REQUEST_REDEEM_REFERRALS';
export const REQUEST_REDEEM_REFERRALS_SUCCESS =
  'REQUEST_REDEEM_REFERRALS_SUCCESS';
export const REQUEST_REDEEM_REFERRALS_ERROR = 'REQUEST_REDEEM_REFERRALS_ERROR';
