import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import subProfileSagas from './subProfiles/saga';
import bookSagas from './books/saga';
import cartSagas from './cart/saga';
import wishlistSagas from './wishlist/saga';
import donationSagas from './donation/saga';
import orderSagas from './orders/saga';
import subscriptionsSagas from './subscriptions/saga';
import reviewSagas from './reviews/saga';
import faqSagas from './faqs/saga';
import blogSagas from './blogs/saga';
import recommendationSagas from './recommendations/saga';
import eventSagas from './events/saga';
import fineSagas from './fines/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    subProfileSagas(),
    bookSagas(),
    cartSagas(),
    wishlistSagas(),
    donationSagas(),
    orderSagas(),
    subscriptionsSagas(),
    reviewSagas(),
    faqSagas(),
    blogSagas(),
    recommendationSagas(),
    eventSagas(),
    fineSagas(),
  ]);
}
