import React from 'react';
import { routeConstants } from 'shared/constants';

const DonorApply = React.lazy(() => import('./'));

const donorApplyRoute = {
  path: routeConstants.DONOR_APPLY.route,
  component: DonorApply,
};

export default donorApplyRoute;
