import React from 'react';
import { routeConstants } from 'shared/constants';
const Wishlist = React.lazy(() => import('./'));

const wishlistRoute = {
  path: routeConstants.ACCOUNT.subRoutes.WISHLIST.route,
  component: Wishlist,
};

export default wishlistRoute;
