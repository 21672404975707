import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { imageConfigs } from 'shared/constants';
import defaultImage from 'assets/images/default-book.png';
import {
  addCartItem,
  addWishlistItem,
  removeWishlistItem,
} from 'store/actions';
import { useLocalState } from 'shared/hooks';
//import wishlistIcon from 'assets/images/icons/fevourite.png';
//import removeIcon from 'assets/images/icons/remove.png';
import { ReactComponent as CartLogo } from 'assets/images/svg/add-cart.svg';
import { ReactComponent as CartLogoFilled } from 'assets/images/svg/add-cart-filled.svg';
import { ReactComponent as WishlistLogo } from 'assets/images/svg/wishlist.svg';
import { ReactComponent as WishlistLogoFilled } from 'assets/images/svg/wishlist-filled.svg';
import { ReactComponent as CartDelete } from 'assets/images/svg/cart-alt.svg';
import { ImageWithLoading } from 'shared/components';
import ReactTooltip from 'react-tooltip';
import switchCaseError from 'shared/utils/switchCaseError';
import { toast } from 'react-toastify';

const { IMAGE_URL } = imageConfigs;

const HomeBookSliderItem = ({ bookData, wishlist, donation, title }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const itemAdding = useSelector((state) => state.cart.itemAdding);
  const cartError = useSelector((state) => state.cart.error);
  const wishlisting = useSelector((state) => state.wishlist.itemAdding);
  const wishlistError = useSelector((state) => state.wishlist.error);
  const wishlistRemoving = useSelector((state) => state.wishlist.removing);

  const {
    bookTitle,
    bookCover,
    bookId,
    wishlistItemId,
    borrowCount,
    available = true,
    cartEnabled = true,
    wishlisted = false,
    alreadyRead = false,
    addedToCart = false,
    tooloNominates = false,
  } = bookData.books[0];
  const [wishlistedNow, changeWishlistSubmit] = useLocalState(
    wishlisted,
    undefined,
    wishlistError,
    wishlisting
  );
  const [cartOpen, changeCartSubmit] = useLocalState(
    !addedToCart,
    false,
    cartError,
    itemAdding
  );
  const imageUrl = bookCover ? `${IMAGE_URL}/${bookCover}` : defaultImage;

  //const secondaryIcon = wishlist ? removeIcon : wishlistIcon;

  const handleAddToCartClicked = () => {
    dispatch(addCartItem({ bookId }));
    changeCartSubmit(true);
  };

  const onImageClicked = () => {
    window.open(`/book-details/${bookId}`, '_blank');
  };

  const handleAddToWishListClicked = () => {
    dispatch(addWishlistItem({ bookId }));
    changeWishlistSubmit(true);
  };

  const handleRemoveWishlistItem = () => {
    dispatch(removeWishlistItem({ wishlistItemId }));
  };

  const redirectLoginPage = () => {
    history.push(switchCaseError('cart').path);
    toast.error(switchCaseError('cart').message);
  };

  const wishListRedirectToLoginPage = () => {
    history.push(switchCaseError('wishList').path);
    toast.error(switchCaseError('wishList').message);
  };

  let cartMessage;
  if (!available) {
    cartMessage = 'Unavailable';
  } else if (!cartOpen) {
    cartMessage = 'Added to cart';
  } else if (!cartEnabled) {
    cartMessage = 'Currently reading';
  } else {
    cartMessage = 'Add to cart';
  }

  return (
    <div className="slide home-most-borrowed-item">
      <div className="border-item">
        <h4 className="category-title">{title}</h4>
        <div className="eachbook">
          <div>
            <div
              className="bookcoverrest"
              onClick={onImageClicked}
              style={{ cursor: 'pointer' }}
            >
              <ReactTooltip id="nominate-check" place="bottom">
                <span>Töölö recommends</span>
              </ReactTooltip>
              {tooloNominates && !wishlist && !donation && (
                <div
                  className="toolo-nominates"
                  data-tip={true}
                  data-for="nominate-check"
                >
                  <i className="fas fa-crown"></i>
                </div>
              )}
              <ImageWithLoading
                src={imageUrl}
                alt={bookTitle}
                className="img-responsive"
              />
              {!available && <div className="isfined">Unavailable</div>}
            </div>
            <div className="bbokrestinfo">
              <p className="authorname">{bookTitle}</p>
              <div className="ratingn">
                <div className="Stars" style={{ '--rating': 4 }} />
              </div>
              {donation ? (
                <div
                  className={`actionbuttons ${
                    !cartOpen || wishlistedNow ? 'addedcf' : ''
                  }`}
                  style={{
                    fontSize: '23px',
                  }}
                >
                  Borrow Count: {borrowCount}
                </div>
              ) : (
                <div
                  className={`actionbuttons ${
                    !cartOpen || wishlistedNow ? 'addedcf' : ''
                  }`}
                >
                  <button
                    type="button"
                    className="addcartlt"
                    disabled={
                      itemAdding === bookId ||
                      !available ||
                      !cartOpen ||
                      !cartEnabled
                    }
                    onClick={!user ? redirectLoginPage : handleAddToCartClicked}
                    data-meaning={cartMessage}
                  >
                    {cartOpen ? <CartLogo /> : <CartLogoFilled />}
                  </button>
                  <button
                    type="button"
                    className="removewishlt"
                    onClick={
                      !user
                        ? wishListRedirectToLoginPage
                        : wishlist
                        ? handleRemoveWishlistItem
                        : handleAddToWishListClicked
                    }
                    disabled={
                      wishlisting === bookId ||
                      wishlistRemoving === wishlistItemId
                    }
                    data-meaning={
                      wishlistedNow
                        ? wishlist
                          ? 'Delete'
                          : 'Added to Wishlist'
                        : 'Wishlist'
                    }
                  >
                    {wishlistedNow ? (
                      wishlist ? (
                        <CartDelete />
                      ) : (
                        <WishlistLogoFilled />
                      )
                    ) : (
                      <WishlistLogo />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
          <ReactTooltip id="read-check" place="bottom">
            <span>Read</span>
          </ReactTooltip>
          {alreadyRead && (
            <div className="alreadyread" data-tip={true} data-for="read-check">
              <i className="fa fa-check" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeBookSliderItem;
