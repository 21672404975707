import { useState } from 'react';
import placeholderImg from 'assets/images/place-holder.jpg';

const ImageWithLoading = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {!loaded ? <img {...props} src={placeholderImg} alt="loading" /> : null}
      <img
        src={src}
        alt={alt}
        {...props}
        onLoad={() => setLoaded(true)}
        style={!loaded ? { visibility: 'hidden', display: 'none' } : {}}
      />
    </>
  );
};

export default ImageWithLoading;
