import React from 'react';
import ReactDOM from 'react-dom';
import InitApp from './initApp';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.css';
import './fonts.css';
import './icons.css';
import './index.css';

Sentry.init({
  dsn:
    'https://0ae1f073c75d43cca8955cfcf4b4264f@o274992.ingest.sentry.io/5667864',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <InitApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
