import { useState, useEffect } from 'react';
import { useSubmitResponse } from 'shared/hooks';

export const useLocalState = (initialState, alternateState, error, loading) => {
  const [state, changeState] = useState(initialState);

  useEffect(() => {
    changeState(initialState);
  }, [initialState]);

  const [changeSubmitStatus] = useSubmitResponse({
    error,
    loading,
    callback: () =>
      changeState(alternateState === undefined ? !state : alternateState),
  });
  return [state, changeSubmitStatus];
};
