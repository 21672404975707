import React from 'react';
import { routeConstants } from 'shared/constants';
const ResetPassword = React.lazy(() => import('./'));

const resetPasswordRoute = {
  path: routeConstants.RESET_PASSWORD.route,
  component: ResetPassword,
};

export default resetPasswordRoute;
