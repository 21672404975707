import {
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_ERROR,
  FETCH_ACTIVE_ORDERS,
  FETCH_ACTIVE_ORDERS_SUCCESS,
  FETCH_ACTIVE_ORDERS_ERROR,
  FETCH_ORDER_HISTORY,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_ERROR,
  FETCH_PICKUP_ORDERS,
  FETCH_PICKUP_ORDERS_SUCCESS,
  FETCH_PICKUP_ORDERS_ERROR,
} from 'store/actionTypes';

export const checkout = () => ({
  type: ADD_ORDER,
});

export const checkoutSuccess = (data) => ({
  type: ADD_ORDER_SUCCESS,
  payload: data,
});

export const checkoutError = (message) => ({
  type: ADD_ORDER_ERROR,
  payload: message,
});

export const fetchActiveOrders = () => ({
  type: FETCH_ACTIVE_ORDERS,
});

export const fetchActiveOrdersSuccess = (data) => ({
  type: FETCH_ACTIVE_ORDERS_SUCCESS,
  payload: data,
});

export const fetchActiveOrdersError = (message) => ({
  type: FETCH_ACTIVE_ORDERS_ERROR,
  payload: message,
});

export const fetchPickupOrders = () => ({
  type: FETCH_PICKUP_ORDERS,
});

export const fetchPickupOrdersSuccess = (data) => ({
  type: FETCH_PICKUP_ORDERS_SUCCESS,
  payload: data,
});

export const fetchPickupOrdersError = (message) => ({
  type: FETCH_PICKUP_ORDERS_ERROR,
  payload: message,
});

export const fetchOrderHistory = (config) => ({
  type: FETCH_ORDER_HISTORY,
  payload: config,
});

export const fetchOrderHistorySuccess = (data) => ({
  type: FETCH_ORDER_HISTORY_SUCCESS,
  payload: data,
});

export const fetchOrderHistoryError = (message) => ({
  type: FETCH_ORDER_HISTORY_ERROR,
  payload: message,
});
