import {
  FETCH_BOOK_REVIEWS,
  FETCH_BOOK_REVIEWS_SUCCESS,
  FETCH_BOOK_REVIEWS_ERROR,
} from 'store/actionTypes';

export const fetchBookReviews = (data) => ({
  type: FETCH_BOOK_REVIEWS,
  payload: data,
});

export const fetchBookReviewsSuccess = (data) => ({
  type: FETCH_BOOK_REVIEWS_SUCCESS,
  payload: data,
});

export const fetchBookReviewsError = (message) => ({
  type: FETCH_BOOK_REVIEWS_ERROR,
  payload: message,
});
