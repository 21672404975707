import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getBookRibbons,
  getRibbonBooks,
  getBookDetails,
  browseBooks,
  getHomeRibbons,
  getRelatedBooks,
  getPreviewRibbons,
} from 'services';
import {
  fetchBookRibbonsSuccess,
  fetchBookRibbonsError,
  fetchRibbonBooksSuccess,
  fetchRibbonBooksError,
  fetchBookDetailsSuccess,
  fetchBookDetailsError,
  browseBooksSuccess,
  browseBooksError,
  fetchHomeRibbonsSuccess,
  fetchHomeRibbonsError,
  fetchRelatedBooksSuccess,
  fetchRelatedBooksError,
  fetchPreviewRibbonsSuccess,
  fetchPreviewRibbonsError,
} from 'store/actions';
import {
  FETCH_BOOK_RIBBONS,
  FETCH_RIBBON_BOOKS,
  FETCH_BOOK_DETAILS,
  BROWSE_BOOKS,
  FETCH_HOME_RIBBONS,
  FETCH_RELATED_BOOKS,
  FETCH_PREVIEW_RIBBONS,
} from 'store/actionTypes';

function* fetchBookRibbons() {
  try {
    const userObj = yield call(getBookRibbons);
    if (!userObj.message) {
      yield put(fetchBookRibbonsSuccess(userObj));
    } else {
      yield put(fetchBookRibbonsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchBookRibbonsError(error));
  }
}

export function* watchFetchBookRibbons() {
  yield takeLatest(FETCH_BOOK_RIBBONS, fetchBookRibbons);
}

function* fetchRibbonBooks({ payload }) {
  try {
    const userObj = yield call(getRibbonBooks, payload);
    if (!userObj.message) {
      yield put(
        fetchRibbonBooksSuccess({ ...userObj, append: payload.append })
      );
    } else {
      yield put(fetchRibbonBooksError(userObj.message));
    }
  } catch (error) {
    yield put(fetchRibbonBooksError(error));
  }
}

export function* watchFetchRibbonBooks() {
  yield takeLatest(FETCH_RIBBON_BOOKS, fetchRibbonBooks);
}

function* fetchBookDetails({ payload }) {
  try {
    const userObj = yield call(getBookDetails, payload);
    if (!userObj.message) {
      yield put(fetchBookDetailsSuccess(userObj));
    } else {
      yield put(fetchBookDetailsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchBookDetailsError(error));
  }
}

export function* watchFetchBookDetails() {
  yield takeLatest(FETCH_BOOK_DETAILS, fetchBookDetails);
}

function* searchBooks({ payload }) {
  const { append, ...restProps } = payload;
  try {
    const userObj = yield call(browseBooks, restProps);
    if (!userObj.message) {
      yield put(browseBooksSuccess({ books: [...userObj], append }));
    } else {
      yield put(browseBooksError(userObj.message));
    }
  } catch (error) {
    yield put(browseBooksError(error));
  }
}

export function* watchSearchBooks() {
  yield takeLatest(BROWSE_BOOKS, searchBooks);
}

function* fetchHomeRibbons() {
  try {
    const userObj = yield call(getHomeRibbons);
    if (!userObj.message) {
      yield put(fetchHomeRibbonsSuccess(userObj));
    } else {
      yield put(fetchHomeRibbonsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchHomeRibbonsError(error));
  }
}

export function* watchFetchHomeRibbons() {
  yield takeLatest(FETCH_HOME_RIBBONS, fetchHomeRibbons);
}

function* fetchRelatedBooks({ payload }) {
  try {
    const userObj = yield call(getRelatedBooks, payload);
    if (!userObj.error) {
      yield put(fetchRelatedBooksSuccess(userObj));
    } else {
      yield put(fetchRelatedBooksError(userObj.message));
    }
  } catch (error) {
    yield put(fetchRelatedBooksError(error));
  }
}

export function* watchFetchRelatedBooks() {
  yield takeLatest(FETCH_RELATED_BOOKS, fetchRelatedBooks);
}

function* fetchPreviewRibbons({ payload }) {
  const { append, ...restProps } = payload;
  try {
    const userObj = yield call(getPreviewRibbons, restProps);
    if (!userObj.message) {
      yield put(
        fetchPreviewRibbonsSuccess({
          books: [...userObj],
          append,
        })
      );
    } else {
      yield put(fetchPreviewRibbonsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchPreviewRibbonsError(error));
  }
}

export function* watchFetchPreviewRibbons() {
  yield takeLatest(FETCH_PREVIEW_RIBBONS, fetchPreviewRibbons);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchBookRibbons),
    fork(watchFetchRibbonBooks),
    fork(watchFetchBookDetails),
    fork(watchSearchBooks),
    fork(watchFetchHomeRibbons),
    fork(watchFetchRelatedBooks),
    fork(watchFetchPreviewRibbons),
  ]);
}
