import Slider from 'react-slick';
import { slickConfigs } from 'shared/constants';
import { BookSliderItem } from 'shared/components';
import HomeBookSliderItem from '../BookSliderItem/HomeBookSliderItem';

const { borrowedBooks, mostBorrowedHome } = slickConfigs;

const BookSlider = ({ bookList, title, type = '' }) => {
  return (
    <Slider
      className="slider borrowedbook"
      {...(type === 'home' ? { ...mostBorrowedHome } : { ...borrowedBooks })}
    >
      {type === 'home'
        ? bookList.map((bookItem) => {
            return (
              <HomeBookSliderItem
                bookData={bookItem}
                key={bookItem.key}
                title={bookItem.title}
              />
            );
          })
        : bookList.map((bookItem) => {
            return (
              <BookSliderItem
                bookData={bookItem}
                key={bookItem.bookId}
                title={title}
              />
            );
          })}
    </Slider>
  );
};

export default BookSlider;
