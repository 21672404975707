import {
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_ERROR,
} from 'store/actionTypes';

export const fetchSubscriptions = (data) => ({
  type: FETCH_SUBSCRIPTIONS,
  payload: data,
});

export const fetchSubscriptionsSuccess = (data) => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const fetchSubscriptionsError = (message) => ({
  type: FETCH_SUBSCRIPTIONS_ERROR,
  payload: message,
});
