import {
  FETCH_FAQS,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_ERROR,
  FETCH_TESTIMONIALS,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  faqData: [],
  testimonials: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FAQS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        faqData: [...action.payload],
        error: '',
      };
    case FETCH_FAQS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_TESTIMONIALS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        testimonials: [...action.payload],
        error: '',
      };
    case FETCH_TESTIMONIALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
