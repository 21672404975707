import Skeleton from 'react-loading-skeleton';

const RibbonPlaceholder = () => {
  return (
    <section className="borrowbook-slider">
      <div className="body-container">
        <div className="zoneheadingbox">
          <div className="sectionheader">
            <Skeleton style={{ minWidth: 100 }} />
          </div>
        </div>
        <Skeleton style={{ minHeight: 220, maxHeight: 220 }} />
      </div>
    </section>
  );
};

export default RibbonPlaceholder;
