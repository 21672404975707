import { useEffect, useState } from 'react';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { imageConfigs, routeConstants } from 'shared/constants';
import {
  checkAuth,
  fetchCartCount,
  fetchFines,
  resetLogoutRedirection,
  toggleAccountSidemenu,
  userLogout,
} from 'store/actions';
import { ImageWithLoading } from 'shared/components';
import Sidebar from '../screens/Account/components/Sidebar';
import { toast } from 'react-toastify';
import { Image } from 'react-bootstrap';
const { IMAGE_URL } = imageConfigs;

const {
  HOME,
  AUTH,
  ACCOUNT,
  BROWSE_BOOKS,
  SUBSCRIPTION_PLANS,
  CONTACT_US,
  ABOUT_US,
  AMBASSADOR,
  EVENTS,
  BLOGS,
  COMING_SOON,
  PRESCHOOL,
} = routeConstants;
const { LOGIN } = AUTH.subRoutes;
const { DASHBOARD, BORROWED_BOOKS, WISHLIST, MY_ACCOUNT } = ACCOUNT.subRoutes;

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const authUser = useSelector((state) => state.auth.user);
  const redirection = useSelector((state) => state.auth.redirection);
  const cartCount = useSelector((state) => state.cart.cartCount);
  const [mobileMenu, toggleMobileMenu] = useState(false);
  const [headerClasses, setHeaderClasses] = useState('bookself');

  const handleScroll = () => {
    const scroll =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    if (scroll >= 350) {
      setHeaderClasses('bookself sticky');
    } else {
      setHeaderClasses('bookself');
    }
  };

  useEffect(() => {
    if (window.fbq) {
      window.fbq('track', 'ViewContent');
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (!!authUser) {
      dispatch(fetchCartCount());
      dispatch(fetchFines());
    }
  }, [dispatch, authUser]);

  useEffect(() => {
    if (redirection) {
      dispatch(resetLogoutRedirection());
      history.push(LOGIN.route);
    }
  }, [redirection, dispatch, history]);

  const handleMenuToggle = (e) => {
    e.stopPropagation();
    toggleMobileMenu((prevState) => {
      if (!prevState) {
        document.body.classList.add('overflowhide');
      } else {
        document.body.classList.remove('overflowhide');
      }
      return !prevState;
    });
  };

  const handleOnMenuItemClicked = () => {
    if (mobileMenu) {
      toggleMobileMenu((prevState) => !prevState);
      document.body.classList.remove('overflowhide');
    }
  };

  const handleLogoutClicked = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(userLogout());
    history.push(HOME.route);
  };

  const toggleAccountMobileMenu = (value) => {
    dispatch(toggleAccountSidemenu(value));
  };

  const handleAccountMenuToggle = (e) => {
    e.stopPropagation();
    if (!mobileMenu) {
      document.body.classList.add('overflowhide');
    } else {
      document.body.classList.remove('overflowhide');
    }
    toggleAccountMobileMenu(!mobileMenu);
  };
  function displayCartMessage() {
    if (cartCount && cartCount > 0) {
      toast.success('Click on Checkout to place the order.');
    }
  }
  return (
    <>
      <section className={headerClasses} id="bookself">
        <div className="body-container">
          <div className="top-logo-bar">
            <div className="top-logo-left">
              <NavLink to={HOME.route} activeClassName="active">
                <img
                  src={require('assets/images/header-logo.png').default}
                  alt="Töölö"
                  className="img-responsive"
                />
              </NavLink>
            </div>
            <div className="top-menu-right">
              <ul>
                <li>
                  <a href="tel:919980434147">
                    <img
                      src={require('assets/images/phone-h.png').default}
                      alt="phone"
                      className="img-responsive"
                    />
                    <span>+91 99804 34147</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:admin@toolo.in">
                    <img
                      src={require('assets/images/mail-h.png').default}
                      alt="phone"
                      className="img-responsive"
                    />
                    <span>admin@toolo.in</span>
                  </a>
                </li>
                {auth.user ? (
                  <li className="hassubmenu">
                    <NavLink to={DASHBOARD.route} activeClassName="active">
                      Account
                    </NavLink>
                    <ul>
                      <li>
                        <Link to={BORROWED_BOOKS.route}>Borrowed books</Link>
                      </li>
                      <li>
                        <Link to={WISHLIST.route}>Wishlist</Link>
                      </li>
                      <li>
                        <Link to={MY_ACCOUNT.route}>Profile</Link>
                      </li>
                      <li>
                        <a href="/" onClick={handleLogoutClicked}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="loginlistts headerlogin">
                    <NavLink to={LOGIN.route} activeClassName="active">
                      Login
                    </NavLink>
                  </li>
                )}
                <li onClick={displayCartMessage}>
                  <NavLink
                    to={routeConstants.CART.route}
                    activeClassName="active"
                    className={cartCount && cartCount > 0 ? 'active' : ''}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version={1.0}
                      viewBox="0 0 40.000000 34.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,34.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path d="M5 331 c-3 -5 9 -11 27 -13 l32 -3 22 -102 c12 -56 24 -109 27 -118 5 -12 27 -15 122 -15 70 0 115 4 115 10 0 6 -43 10 -110 10 -146 0 -145 18 2 22 l112 3 11 45 c6 25 14 62 18 83 l6 37 -149 0 -148 0 -6 25 c-5 21 -12 25 -41 25 -18 0 -36 -4 -40 -9z m165 -87 c0 -23 -3 -25 -32 -22 -25 2 -34 8 -36 26 -3 19 1 22 32 22 32 0 36 -3 36 -26z m100 0 c0 -23 -3 -25 -37 -22 -31 2 -39 7 -41 26 -3 20 1 22 37 22 37 0 41 -2 41 -26z m88 4 c-2 -18 -11 -24 -35 -26 -30 -3 -33 -1 -33 22 0 23 4 26 36 26 31 0 35 -3 32 -22z m-180 -80 c3 -24 0 -28 -23 -28 -17 0 -28 7 -34 23 -12 30 -4 39 27 35 21 -2 28 -9 30 -30z m82 1 c0 -27 -3 -30 -27 -27 -22 2 -29 9 -31 31 -3 24 0 27 27 27 28 0 31 -3 31 -31z m83 24 c10 -16 -13 -53 -32 -53 -25 0 -32 11 -24 38 5 21 46 32 56 15z" />
                        <path d="M140 45 c-15 -18 -5 -35 21 -35 12 0 19 7 19 19 0 24 -25 34 -40 16z" />
                        <path d="M293 53 c-21 -8 -15 -43 6 -43 11 0 23 5 27 11 8 14 -17 38 -33 32z" />
                      </g>
                    </svg>
                    <sup>{cartCount}</sup>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <header className="menubararea" id="menubararea">
          <div className="body-container">
            <div className="menulist" onClick={handleOnMenuItemClicked}>
              <div className="mobilemenu">
                <div className="mobilelogo">
                  <Link to={HOME.route}>
                    <img
                      src={require('assets/images/header-logo.png').default}
                      alt="Töölö"
                      className="img-responsive"
                    />
                  </Link>
                </div>
                <div className="mobilerighertst">
                  {authUser && (
                    <div className="mob-profile-img">
                      <ImageWithLoading
                        src={`${IMAGE_URL}/${authUser.profileImage}`}
                        alt={authUser.fullName}
                        className="img-responsive"
                        onClick={handleAccountMenuToggle}
                      />
                    </div>
                  )}
                  <div
                    className={`leftmenutab ${mobileMenu ? 'yesopened' : ''}`}
                    onClick={(e) => handleMenuToggle(e)}
                  >
                    <div className="bar1" />
                    <div className="bar2" />
                    <div className="bar3" />
                  </div>
                  <div className="mobilecart" onClick={displayCartMessage}>
                    {' '}
                    <NavLink
                      to={routeConstants.CART.route}
                      activeClassName="active"
                      className={cartCount && cartCount > 0 ? 'active' : ''}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version={1.0}
                        viewBox="0 0 40.000000 34.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,34.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path d="M5 331 c-3 -5 9 -11 27 -13 l32 -3 22 -102 c12 -56 24 -109 27 -118 5 -12 27 -15 122 -15 70 0 115 4 115 10 0 6 -43 10 -110 10 -146 0 -145 18 2 22 l112 3 11 45 c6 25 14 62 18 83 l6 37 -149 0 -148 0 -6 25 c-5 21 -12 25 -41 25 -18 0 -36 -4 -40 -9z m165 -87 c0 -23 -3 -25 -32 -22 -25 2 -34 8 -36 26 -3 19 1 22 32 22 32 0 36 -3 36 -26z m100 0 c0 -23 -3 -25 -37 -22 -31 2 -39 7 -41 26 -3 20 1 22 37 22 37 0 41 -2 41 -26z m88 4 c-2 -18 -11 -24 -35 -26 -30 -3 -33 -1 -33 22 0 23 4 26 36 26 31 0 35 -3 32 -22z m-180 -80 c3 -24 0 -28 -23 -28 -17 0 -28 7 -34 23 -12 30 -4 39 27 35 21 -2 28 -9 30 -30z m82 1 c0 -27 -3 -30 -27 -27 -22 2 -29 9 -31 31 -3 24 0 27 27 27 28 0 31 -3 31 -31z m83 24 c10 -16 -13 -53 -32 -53 -25 0 -32 11 -24 38 5 21 46 32 56 15z" />
                          <path d="M140 45 c-15 -18 -5 -35 21 -35 12 0 19 7 19 19 0 24 -25 34 -40 16z" />
                          <path d="M293 53 c-21 -8 -15 -43 6 -43 11 0 23 5 27 11 8 14 -17 38 -33 32z" />
                        </g>
                      </svg>
                      <sup>{cartCount}</sup>
                    </NavLink>
                  </div>
                  <div className="contact-header-iconss">
                    <a href="mailto:admin@toolo.in">
                      <img
                        src={require('assets/images/mail-h.png').default}
                        alt="phone"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                  <div className="contact-header-iconss">
                    <a href="tel:919980434147">
                      <img
                        src={require('assets/images/phone-h.png').default}
                        alt="phone"
                        className="img-responsive"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <ul
                className={`desktop-menulist ${
                  mobileMenu ? 'mobilemenu-visible' : 'mobilemenu-hide'
                }`}
              >
                <li>
                  <NavLink to={HOME.route} activeClassName="active">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version={1.0}
                      viewBox="0 0 39.000000 31.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,31.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path d="M96 231 c-74 -61 -93 -81 -83 -90 10 -8 33 6 97 59 72 60 87 68 101 57 10 -6 50 -39 89 -71 57 -47 73 -57 80 -45 7 10 1 21 -20 37 -27 21 -30 29 -30 78 0 51 -1 54 -25 54 -20 0 -25 -5 -25 -25 0 -14 -4 -25 -9 -25 -5 0 -23 11 -39 25 -17 14 -32 25 -35 25 -3 0 -49 -36 -101 -79z" />
                        <path d="M126 188 l-65 -53 -1 -67 0 -68 50 0 50 0 0 45 c0 45 0 45 35 45 35 0 35 0 35 -45 l0 -45 50 0 50 0 0 68 0 67 -65 53 c-36 28 -67 52 -69 52 -2 0 -34 -24 -70 -52z" />
                      </g>
                    </svg>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ABOUT_US.route} activeClassName="active">
                    Our Story
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={SUBSCRIPTION_PLANS.route}
                    activeClassName="active"
                  >
                    Our Plans
                  </NavLink>
                </li>
                <li>
                  <NavLink to={BROWSE_BOOKS.route} activeClassName="active">
                    Browse Books
                  </NavLink>
                </li>
                <li>
                  <Link to={EVENTS.route}>Events</Link>
                </li>
                <li className="hassubmenu">
                  <NavLink to={AMBASSADOR.route} activeClassName="active">
                    Partner With Us
                  </NavLink>
                  <ul>
                    <li>
                      <Link to={AMBASSADOR.route}>
                        Library for Neighborhoods
                      </Link>
                    </li>
                    <li>
                      <Link to={PRESCHOOL.route}>Library for Preschools</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={BLOGS.route}>Blogs</Link>
                </li>
                <li>
                  <NavLink to={CONTACT_US.route} activeClassName="active">
                    Contact
                  </NavLink>
                </li>
                {auth.user ? (
                  <li className="hassubmenu">
                    <NavLink to={DASHBOARD.route} activeClassName="active">
                      Account
                    </NavLink>
                    <ul>
                      <li>
                        <Link to={BORROWED_BOOKS.route}>Borrowed books</Link>
                      </li>
                      <li>
                        <Link to={WISHLIST.route}>Wishlist</Link>
                      </li>
                      <li>
                        <Link to={MY_ACCOUNT.route}>Profile</Link>
                      </li>
                      <li>
                        <a href="/" onClick={handleLogoutClicked}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="loginlistts">
                    <NavLink to={LOGIN.route} activeClassName="active">
                      Login
                    </NavLink>
                  </li>
                )}
                <li onClick={displayCartMessage}>
                  <NavLink
                    to={routeConstants.CART.route}
                    activeClassName="active"
                    className={cartCount && cartCount > 0 ? 'active' : ''}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version={1.0}
                      viewBox="0 0 40.000000 34.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,34.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path d="M5 331 c-3 -5 9 -11 27 -13 l32 -3 22 -102 c12 -56 24 -109 27 -118 5 -12 27 -15 122 -15 70 0 115 4 115 10 0 6 -43 10 -110 10 -146 0 -145 18 2 22 l112 3 11 45 c6 25 14 62 18 83 l6 37 -149 0 -148 0 -6 25 c-5 21 -12 25 -41 25 -18 0 -36 -4 -40 -9z m165 -87 c0 -23 -3 -25 -32 -22 -25 2 -34 8 -36 26 -3 19 1 22 32 22 32 0 36 -3 36 -26z m100 0 c0 -23 -3 -25 -37 -22 -31 2 -39 7 -41 26 -3 20 1 22 37 22 37 0 41 -2 41 -26z m88 4 c-2 -18 -11 -24 -35 -26 -30 -3 -33 -1 -33 22 0 23 4 26 36 26 31 0 35 -3 32 -22z m-180 -80 c3 -24 0 -28 -23 -28 -17 0 -28 7 -34 23 -12 30 -4 39 27 35 21 -2 28 -9 30 -30z m82 1 c0 -27 -3 -30 -27 -27 -22 2 -29 9 -31 31 -3 24 0 27 27 27 28 0 31 -3 31 -31z m83 24 c10 -16 -13 -53 -32 -53 -25 0 -32 11 -24 38 5 21 46 32 56 15z" />
                        <path d="M140 45 c-15 -18 -5 -35 21 -35 12 0 19 7 19 19 0 24 -25 34 -40 16z" />
                        <path d="M293 53 c-21 -8 -15 -43 6 -43 11 0 23 5 27 11 8 14 -17 38 -33 32z" />
                      </g>
                    </svg>
                    <sup>{cartCount}</sup>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <Sidebar
          userData={authUser || {}}
          onLogoutClicked={handleLogoutClicked}
          section="header"
        />
      </section>
      <div className="clearfix" />
    </>
  );
};

export default Header;
