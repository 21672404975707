import {
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_ERROR,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  CHECK_AUTH,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  RESET_LOGOUT_REDIRECTION,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_IMAGES,
  FETCH_USER_IMAGES_SUCCESS,
  FETCH_USER_IMAGES_ERROR,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,
  FACEBOOK_LOGIN,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_ERROR,
  SET_AUTH_LOADING,
  FETCH_MY_REFERRALS,
  FETCH_MY_REFERRALS_SUCCESS,
  FETCH_MY_REFERRALS_ERROR,
  FETCH_REFERRAL_STATS,
  FETCH_REFERRAL_STATS_SUCCESS,
  FETCH_REFERRAL_STATS_ERROR,
  REQUEST_REDEEM_REFERRALS,
  REQUEST_REDEEM_REFERRALS_ERROR,
  REQUEST_REDEEM_REFERRALS_SUCCESS,
} from '../actionTypes';

export const userRegistration = (data) => ({
  type: USER_REGISTRATION,
  payload: data,
});

export const userRegistrationSuccess = (user) => ({
  type: USER_REGISTRATION_SUCCESS,
  payload: user,
});

export const userRegistrationError = (message) => ({
  type: USER_REGISTRATION_ERROR,
  payload: message,
});

export const userLogin = (data) => ({
  type: USER_LOGIN,
  payload: data,
});

export const userLoginSuccess = (user) => ({
  type: USER_LOGIN_SUCCESS,
  payload: user,
});

export const userLoginError = (message) => ({
  type: USER_LOGIN_ERROR,
  payload: message,
});

export const googleLogin = (data) => ({
  type: GOOGLE_LOGIN,
  payload: data,
});

export const googleLoginSuccess = (user) => ({
  type: GOOGLE_LOGIN_SUCCESS,
  payload: user,
});

export const googleLoginError = (message) => ({
  type: GOOGLE_LOGIN_ERROR,
  payload: message,
});

export const facebookLogin = (data) => ({
  type: FACEBOOK_LOGIN,
  payload: data,
});

export const facebookLoginSuccess = (user) => ({
  type: FACEBOOK_LOGIN_SUCCESS,
  payload: user,
});

export const facebookLoginError = (message) => ({
  type: FACEBOOK_LOGIN_ERROR,
  payload: message,
});

export const setAuthLoading = () => ({
  type: SET_AUTH_LOADING,
});

export const checkAuth = () => ({
  type: CHECK_AUTH,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const userLogoutSuccess = (data) => ({
  type: USER_LOGOUT_SUCCESS,
  payload: data,
});

export const userLogoutError = (message) => ({
  type: USER_LOGOUT_ERROR,
  payload: message,
});

export const resetLogoutRedirection = () => ({
  type: RESET_LOGOUT_REDIRECTION,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const updateUserError = (message) => ({
  type: UPDATE_USER_ERROR,
  payload: message,
});

export const fetchUserProfile = () => ({
  type: FETCH_USER_PROFILE,
});

export const fetchUserProfileSuccess = (user) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: user,
});

export const fetchUserProfileError = (message) => ({
  type: FETCH_USER_PROFILE_ERROR,
  payload: message,
});

export const fetchUserImages = () => ({
  type: FETCH_USER_IMAGES,
});

export const fetchUserImagesSuccess = (user) => ({
  type: FETCH_USER_IMAGES_SUCCESS,
  payload: user,
});

export const fetchUserImagesError = (message) => ({
  type: FETCH_USER_IMAGES_ERROR,
  payload: message,
});

export const fetchMyReferrals = () => ({
  type: FETCH_MY_REFERRALS,
});

export const fetchMyReferralsSuccess = (data) => ({
  type: FETCH_MY_REFERRALS_SUCCESS,
  payload: data,
});

export const fetchMyReferralsError = (message) => ({
  type: FETCH_MY_REFERRALS_ERROR,
  payload: message,
});

export const fetchReferralStatistics = () => ({
  type: FETCH_REFERRAL_STATS,
});

export const fetchReferralStatisticsSuccess = (data) => ({
  type: FETCH_REFERRAL_STATS_SUCCESS,
  payload: data,
});

export const fetchReferralStatisticsError = (message) => ({
  type: FETCH_REFERRAL_STATS_ERROR,
  payload: message,
});

export const requestRedeemReferrals = () => ({
  type: REQUEST_REDEEM_REFERRALS,
});

export const requestRedeemReferralsSuccess = (data) => ({
  type: REQUEST_REDEEM_REFERRALS_SUCCESS,
  payload: data,
});

export const requestRedeemReferralsError = (message) => ({
  type: REQUEST_REDEEM_REFERRALS_ERROR,
  payload: message,
});
