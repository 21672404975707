import {
  FETCH_FINES,
  FETCH_FINES_SUCCESS,
  FETCH_FINES_ERROR,
} from 'store/actionTypes';

export const fetchFines = (data) => ({
  type: FETCH_FINES,
  payload: data,
});

export const fetchFinesSuccess = (data) => ({
  type: FETCH_FINES_SUCCESS,
  payload: data,
});

export const fetchFinesError = (message) => ({
  type: FETCH_FINES_ERROR,
  payload: message,
});
