import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { AsyncDropdown, SelectDropdown } from 'shared/components';
import { useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { fetchGenres, getAgeGroups } from 'services';
import { useOptionLoader } from 'shared/hooks';

const BookFilterTop = ({ onSearchClicked, initialData, onReset }) => {
  const [ageGroupOptions, setAgeOptions] = useState([]);
  const [genreOptions, loadGenreOptions] = useOptionLoader(fetchGenres);

  const loadAgeGroupOptions = useCallback(async () => {
    const ageGroups = await getAgeGroups({});
    let ageGroupsOptions = [];
    if (!ageGroups.error) {
      ageGroupsOptions = ageGroups.map((genre) => {
        const { _id, ageGroupName, slug } = genre;
        return {
          value: _id,
          label: `${ageGroupName} (${slug} yrs)`,
        };
      });
    }
    setAgeOptions(ageGroupsOptions);
  }, []);

  useEffect(() => {
    loadAgeGroupOptions();
  }, [loadAgeGroupOptions]);

  return (
    <section className="filterzone">
      <div className="body-container">
        <h1 className="pageheading">Browse Books</h1>
        <div className="filterfldsgroup">
          {ageGroupOptions.length && genreOptions.length ? (
            <Formik
              enableReinitialize
              initialValues={{
                search: initialData.search || '',
                ageGroup: ageGroupOptions.find(
                  (option) => option.value === initialData.ageGroup
                ),
                genre: genreOptions.find(
                  (option) => option.value === initialData.genre
                ),
                available: !!initialData.available,
              }}
              onSubmit={(values, { setSubmitting }) => {
                onSearchClicked(values);
                setSubmitting(false);
              }}
            >
              {() => (
                <Form>
                  <div className="twelve-full">
                    <div className="eachfieldzone">
                      <div className="filter-fldrw">
                        <Field
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Search by Title, Author, Any other tags"
                          aria-label="Search"
                        />
                        <span>
                          <img
                            src={
                              require('assets/images/icons/search.png').default
                            }
                            alt="search"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="one-third">
                    <div className="eachfieldzone">
                      <div className="filter-fldrw">
                        <SelectDropdown
                          name="ageGroup"
                          className="form-control"
                          placeHolder="Age groups"
                          options={ageGroupOptions}
                          selectObjectValue
                          defaultValue={
                            initialData.ageGroup
                              ? { ...initialData.ageGroup }
                              : false
                          }
                          controlled
                        />
                        <span className="forselect">
                          <img
                            src={
                              require('assets/images/icons/select.png').default
                            }
                            alt="select"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="one-third">
                    <ReactTooltip id="happyFace" place="bottom">
                      <span>Coming soon</span>
                    </ReactTooltip>
                    <div className="eachfieldzone">
                      <div
                        className="filter-fldrw"
                        data-tip={true}
                        data-for="happyFace"
                      >
                        <select className="form-control" disabled>
                          <option>Rating</option>
                          <option>4 Star</option>
                          <option>3 Star</option>
                          <option>2 Star</option>
                          <option>1 Star</option>
                        </select>
                        <span className="forselect">
                          <img
                            src={
                              require('assets/images/icons/select.png').default
                            }
                            alt="select"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="one-third">
                    <div className="eachfieldzone">
                      <div className="filter-fldrw">
                        <AsyncDropdown
                          name="genre"
                          placeHolder="Favourite Genres"
                          loadOptions={loadGenreOptions}
                          selectObjectValue
                          defaultOptions={genreOptions}
                          defaultValue={initialData.genre}
                          controlled
                        />
                        <span className="forselect">
                          <img
                            src={
                              require('assets/images/icons/select.png').default
                            }
                            alt="select"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="twelve-full text-center m-0">
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="available"
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Show only available books
                      </label>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="twelve-full text-center">
                    <button type="reset" className="resetbtn" onClick={onReset}>
                      Clear
                    </button>
                    <button type="submit" className="primarybtn">
                      Search
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default BookFilterTop;
