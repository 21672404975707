import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ScrollArrow.css';

const ScrollArrow = () => {
  const location = useLocation();
  const [showScroll, setShowScroll] = useState(false);
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop);

  return (
    <span
      className="scrollTop"
      style={{ height: 40, display: showScroll ? 'block' : 'none' }}
      onClick={scrollTop}
    >
      <i className="fa fa-angle-double-up fa-2x" />
    </span>
  );
};

export default ScrollArrow;
