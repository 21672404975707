import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BookSlider } from 'shared/components';
import switchCaseError from 'shared/utils/switchCaseError';

const BookRibbon = ({ ribbonData, viewAll }) => {
  const { title, books, key } = ribbonData;
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  const redirectToLoginPage = (event) => {
    event.preventDefault();
    history.push(switchCaseError('viewAll').path);
    toast.error(switchCaseError('viewAll').message);
  };
  return (
    <section className="borrowbook-slider">
      <div className="body-container">
        <div className="zoneheadingbox">
          <h2 className="sectionheader">{title}</h2>
          {viewAll === false ? null : (
            <div className="viewallbooks">
              {!!user ? (
                <Link to={`/browse-books/ribbon/${key}`}>
                  View All
                  <i className="fa fa-arrow-right" />
                </Link>
              ) : (
                <a href="/" onClick={redirectToLoginPage}>
                  View All
                  <i className="fa fa-arrow-right" />
                </a>
              )}
            </div>
          )}
        </div>
        <BookSlider bookList={books} />
      </div>
    </section>
  );
};

export default BookRibbon;
