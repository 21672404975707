export const setLoginData = (userObj) => {
  localStorage.setItem('toolo_token', userObj.token);
  localStorage.setItem('toolo_user', JSON.stringify(userObj.user));
};

export const getAccessToken = () => {
  return localStorage.getItem('toolo_token');
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem('toolo_user'));
};

export const setUser = (user) => {
  localStorage.setItem('toolo_user', JSON.stringify(user));
};

export const clearLoginData = () => {
  localStorage.removeItem('toolo_token');
  localStorage.removeItem('toolo_user');
};
