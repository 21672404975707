import React from 'react';
import { routeConstants } from 'shared/constants';
const BlogDetails = React.lazy(() => import('./'));

const blogDetailRoute = {
  path: routeConstants.BLOG_DETAILS.route,
  component: BlogDetails,
  exact: routeConstants.BLOG_DETAILS.exact,
};

export default blogDetailRoute;
