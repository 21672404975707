import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getFaq, getTestimonials } from 'services';
import {
  fetchFaqsSuccess,
  fetchFaqsError,
  fetchTestimonialsSuccess,
  fetchTestimonialsError,
} from 'store/actions';
import { FETCH_FAQS, FETCH_TESTIMONIALS } from 'store/actionTypes';

function* fetchFAQs() {
  try {
    const userObj = yield call(getFaq);
    if (!userObj.message) {
      yield put(fetchFaqsSuccess(userObj));
    } else {
      yield put(fetchFaqsError(userObj));
    }
  } catch (error) {
    yield put(fetchFaqsError(error));
  }
}

export function* watchFaqs() {
  yield takeLatest(FETCH_FAQS, fetchFAQs);
}

function* fetchTestimonialData() {
  try {
    const userObj = yield call(getTestimonials);
    if (!userObj.message) {
      yield put(fetchTestimonialsSuccess(userObj));
    } else {
      yield put(fetchTestimonialsError(userObj));
    }
  } catch (error) {
    yield put(fetchTestimonialsError(error));
  }
}

export function* watchTestimonials() {
  yield takeLatest(FETCH_TESTIMONIALS, fetchTestimonialData);
}

export default function* rootSaga() {
  yield all([fork(watchFaqs), fork(watchTestimonials)]);
}
