import React from 'react';
import { routeConstants } from 'shared/constants';

const ContactUs = React.lazy(() => import('./'));

const contactUsRoute = {
  path: routeConstants.CONTACT_US.route,
  component: ContactUs,
};

export default contactUsRoute;
