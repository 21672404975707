import {
  FETCH_FAQS,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_ERROR,
  FETCH_TESTIMONIALS,
  FETCH_TESTIMONIALS_SUCCESS,
  FETCH_TESTIMONIALS_ERROR,
} from 'store/actionTypes';

export const fetchFaqs = (data) => ({
  type: FETCH_FAQS,
  payload: data,
});

export const fetchFaqsSuccess = (data) => ({
  type: FETCH_FAQS_SUCCESS,
  payload: data,
});

export const fetchFaqsError = (message) => ({
  type: FETCH_FAQS_ERROR,
  payload: message,
});

export const fetchTestimonials = (data) => ({
  type: FETCH_TESTIMONIALS,
  payload: data,
});

export const fetchTestimonialsSuccess = (data) => ({
  type: FETCH_TESTIMONIALS_SUCCESS,
  payload: data,
});

export const fetchTestimonialsError = (message) => ({
  type: FETCH_TESTIMONIALS_ERROR,
  payload: message,
});
