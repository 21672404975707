import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getDonatedBooks } from 'services';
import { fetchDonateBooksSuccess, fetchDonateBooksError } from 'store/actions';
import { FETCH_DONATED_BOOKS } from 'store/actionTypes';

function* fetchDonatedBooks() {
  try {
    const userObj = yield call(getDonatedBooks);
    if (!userObj.message) {
      yield put(fetchDonateBooksSuccess(userObj));
    } else {
      yield put(fetchDonateBooksError(userObj.message));
    }
  } catch (error) {
    yield put(fetchDonateBooksError(error));
  }
}

export function* watchFetchDonatedBooks() {
  yield takeLatest(FETCH_DONATED_BOOKS, fetchDonatedBooks);
}

export default function* rootSaga() {
  yield all([fork(watchFetchDonatedBooks)]);
}
