import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import App from 'screens/App';
import errorRoute from 'screens/Error/route';

import { store } from 'store';

import { LazyLoader } from 'shared/components';

import { setupAxios } from 'services/axios';
import page404Route from 'screens/404/route';

setupAxios(store.dispatch);

function initApp() {
  return (
    <Provider store={store}>
      <Suspense fallback={''}>
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Switch>
            <Route
              path={errorRoute.path}
              exact
              render={(props) => <errorRoute.component {...props} />}
            />
            <Route
              path={page404Route.path}
              exact
              render={(props) => <page404Route.component {...props} />}
            />
            <Route path="/" component={App} />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default initApp;
