import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getPastEvents,
  getUpcomingEventDetail,
  getUpcomingEvents,
} from 'services';
import {
  fetchPastEventsSuccess,
  fetchPastEventsError,
  fetchUpcomingEventsSuccess,
  fetchUpcomingEventsError,
  fetchUpcomingEventDetailSuccess,
  fetchUpcomingEventDetailError,
} from 'store/actions';
import {
  FETCH_UPCOMING_EVENTS,
  FETCH_PAST_EVENTS,
  FETCH_UPCOMING_EVENT_DETAIL,
} from 'store/actionTypes';

function* fetchPastEventItems() {
  try {
    const userObj = yield call(getPastEvents);
    if (!userObj.message) {
      yield put(fetchPastEventsSuccess(userObj));
    } else {
      yield put(fetchPastEventsError(userObj));
    }
  } catch (error) {
    yield put(fetchPastEventsError(error));
  }
}

export function* watchPastEvents() {
  yield takeLatest(FETCH_PAST_EVENTS, fetchPastEventItems);
}

function* fetchUpcomingEventItems() {
  try {
    const userObj = yield call(getUpcomingEvents);
    if (!userObj.message) {
      yield put(fetchUpcomingEventsSuccess(userObj));
    } else {
      yield put(fetchUpcomingEventsError(userObj));
    }
  } catch (error) {
    yield put(fetchUpcomingEventsError(error));
  }
}

export function* watchUpcomingEvents() {
  yield takeLatest(FETCH_UPCOMING_EVENTS, fetchUpcomingEventItems);
}

function* fetchUpcomingEventDetail({ payload }) {
  try {
    const userObj = yield call(getUpcomingEventDetail, payload);
    if (!userObj.message) {
      yield put(fetchUpcomingEventDetailSuccess(userObj));
    } else {
      yield put(fetchUpcomingEventDetailError(userObj));
    }
  } catch (error) {
    yield put(fetchUpcomingEventDetailError(error));
  }
}

export function* watchUpcomingEventDetail() {
  yield takeLatest(FETCH_UPCOMING_EVENT_DETAIL, fetchUpcomingEventDetail);
}

export default function* rootSaga() {
  yield all([
    fork(watchPastEvents),
    fork(watchUpcomingEvents),
    fork(watchUpcomingEventDetail),
  ]);
}
