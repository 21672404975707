import React from 'react';
import { routeConstants } from 'shared/constants';
const SubscriptionPlans = React.lazy(() => import('./'));

const subscriptionPlansRoute = {
  path: routeConstants.SUBSCRIPTION_PLANS.route,
  component: SubscriptionPlans,
};

export default subscriptionPlansRoute;
