import React from 'react';
import { routeConstants } from 'shared/constants';

const DeliveryPolicy = React.lazy(() => import('./'));

const deliveryPolicyRoute = {
  path: routeConstants.DELIVERY_POLICY.route,
  component: DeliveryPolicy,
};

export default deliveryPolicyRoute;
