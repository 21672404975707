import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  USER_REGISTRATION,
  USER_LOGIN,
  GOOGLE_LOGIN,
  FACEBOOK_LOGIN,
  CHECK_AUTH,
  USER_LOGOUT,
  UPDATE_USER,
  FETCH_USER_PROFILE,
  FETCH_USER_IMAGES,
  FETCH_MY_REFERRALS,
  FETCH_REFERRAL_STATS,
  REQUEST_REDEEM_REFERRALS,
} from 'store/actionTypes';
import localStorageService, {
  registration,
  login,
  googleLogin,
  logout,
  updateUser,
  getUserProfile,
  getProfileImages,
  facebookLogin,
  getMyReferrals,
  getReferralStatistics,
  postRequestRedeemReferrals,
} from 'services';

import {
  userRegistrationSuccess,
  userRegistrationError,
  userLoginSuccess,
  userLoginError,
  userLogoutSuccess,
  userLogoutError,
  updateUserSuccess,
  updateUserError,
  fetchUserProfileSuccess,
  fetchUserProfileError,
  fetchSubProfilesSuccess,
  fetchCartCountSuccess,
  fetchUserImagesSuccess,
  fetchUserImagesError,
  googleLoginSuccess,
  googleLoginError,
  facebookLoginSuccess,
  facebookLoginError,
  toggleSocialModal,
  setAuthLoading,
  fetchMyReferralsSuccess,
  fetchMyReferralsError,
  fetchReferralStatisticsSuccess,
  fetchReferralStatisticsError,
} from 'store/actions';
import {
  requestRedeemReferralsError,
  requestRedeemReferralsSuccess,
} from './actions';

function* registerUser({ payload }) {
  try {
    const registerUser = yield call(registration, payload);
    if (!registerUser.message) {
      yield put(userRegistrationSuccess(registerUser));
    } else {
      yield put(userRegistrationError(registerUser.message));
    }
  } catch (error) {
    yield put(userRegistrationError(error));
  }
}

export function* watchRegisterUser() {
  yield takeLatest(USER_REGISTRATION, registerUser);
}

function* loginUser({ payload }) {
  try {
    const userObj = yield call(login, payload);
    if (!userObj.error) {
      yield call(() => localStorageService.setLoginData({ ...userObj }));
      yield put(userLoginSuccess(userObj.user));
    } else {
      yield put(userLoginError(userObj.message));
    }
  } catch (error) {
    yield put(userLoginError(error));
  }
}

export function* watchLoginUser() {
  yield takeLatest(USER_LOGIN, loginUser);
}

function* googleLoginRequest({ payload }) {
  try {
    const userObj = yield call(googleLogin, payload);
    if (!userObj.error && !userObj.new) {
      yield call(() => localStorageService.setLoginData({ ...userObj }));
      yield put(googleLoginSuccess(userObj.user));
    } else {
      if (userObj.new) {
        yield put(
          toggleSocialModal({ token: payload.token, email: userObj.email })
        );
        yield put(setAuthLoading());
      } else {
        yield put(googleLoginError(userObj.message));
      }
    }
  } catch (error) {
    yield put(googleLoginError(error));
  }
}

export function* watchGoogleLogin() {
  yield takeLatest(GOOGLE_LOGIN, googleLoginRequest);
}

function* facebookLoginRequest({ payload }) {
  try {
    const userObj = yield call(facebookLogin, payload);
    if (!userObj.error && !userObj.new) {
      yield call(() => localStorageService.setLoginData({ ...userObj }));
      yield put(facebookLoginSuccess(userObj.user));
    } else {
      if (userObj.new) {
        yield put(
          toggleSocialModal({ token: payload.token, email: userObj.email })
        );
        yield put(setAuthLoading());
      } else {
        yield put(facebookLoginError(userObj.message));
      }
    }
  } catch (error) {
    yield put(facebookLoginError(error));
  }
}

export function* watchFacebookLogin() {
  yield takeLatest(FACEBOOK_LOGIN, facebookLoginRequest);
}

function* checkAuth() {
  const userData = yield call(() => localStorageService.getUser());
  if (userData) {
    yield put(userLoginSuccess(userData));
    yield put(fetchSubProfilesSuccess(userData.children));
  } else {
    yield call(() => localStorageService.clearLoginData());
    yield put(userLogoutSuccess());
  }
}

export function* watchCheckAuth() {
  yield takeLatest(CHECK_AUTH, checkAuth);
}

function* logoutUser() {
  try {
    const userObj = yield call(logout);
    yield call(() => localStorageService.clearLoginData());
    yield put(fetchCartCountSuccess({ count: 0 }));
    yield put(fetchSubProfilesSuccess([]));
    if (!userObj.message) {
      yield put(userLogoutSuccess());
    } else {
      yield put(userLogoutError(userObj.message));
    }
  } catch (error) {
    yield put(userLogoutError(error));
  }
}

export function* watchLogoutUser() {
  yield takeLatest(USER_LOGOUT, logoutUser);
}

function* updateUserData({ payload }) {
  try {
    const userObj = yield call(updateUser, payload);
    if (!userObj.message) {
      yield call(() => localStorageService.setUser({ ...userObj }));
      yield put(updateUserSuccess(userObj));
    } else {
      yield put(updateUserError(userObj.message));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUserData);
}

function* fetchUserProfile() {
  try {
    const userObj = yield call(getUserProfile);
    if (!userObj.message) {
      yield call(() => localStorageService.setUser({ ...userObj }));
      yield put(fetchUserProfileSuccess(userObj));
    } else {
      yield put(fetchUserProfileError(userObj.message));
    }
  } catch (error) {
    yield put(fetchUserProfileError(error));
  }
}

export function* watchFetchUserProfile() {
  yield takeLatest(FETCH_USER_PROFILE, fetchUserProfile);
}

function* fetchUserImages() {
  try {
    const userObj = yield call(getProfileImages);
    if (!userObj.message) {
      //yield call(() => localStorageService.setUser({ ...userObj }));
      yield put(fetchUserImagesSuccess(userObj));
    } else {
      yield put(fetchUserImagesError(userObj));
    }
  } catch (error) {
    yield put(fetchUserImagesError(error));
  }
}

export function* watchFetchUserImages() {
  yield takeLatest(FETCH_USER_IMAGES, fetchUserImages);
}

// Fetch My Referrals
function* fetchMyReferrals() {
  try {
    const data = yield call(getMyReferrals);
    if (!data.error) {
      yield put(fetchMyReferralsSuccess(data));
    } else {
      yield put(fetchMyReferralsError(data.message));
    }
  } catch (error) {
    yield put(fetchMyReferralsError(error));
  }
}

export function* watchFetchMyReferrals() {
  yield takeLatest(FETCH_MY_REFERRALS, fetchMyReferrals);
}

// Fetch Referral Statistics
function* fetchReferralStatistics() {
  try {
    const data = yield call(getReferralStatistics);
    if (!data.error) {
      yield put(fetchReferralStatisticsSuccess(data));
    } else {
      yield put(fetchReferralStatisticsError(data.message));
    }
  } catch (error) {
    yield put(fetchReferralStatisticsError(error));
  }
}

export function* watchFetchReferralStatistics() {
  yield takeLatest(FETCH_REFERRAL_STATS, fetchReferralStatistics);
}
function* requestRedeemReferrals() {
  try {
    const data = yield call(postRequestRedeemReferrals);
    if (!data.error) {
      yield put(requestRedeemReferralsSuccess(data));
    } else {
      yield put(requestRedeemReferralsError(data.message));
    }
  } catch (error) {
    yield put(requestRedeemReferralsError(error));
  }
}
export function* watchRequestRedeemReferral() {
  yield takeLatest(REQUEST_REDEEM_REFERRALS, requestRedeemReferrals);
}

export default function* rootSaga() {
  yield all([
    fork(watchRegisterUser),
    fork(watchLoginUser),
    fork(watchGoogleLogin),
    fork(watchFacebookLogin),
    fork(watchCheckAuth),
    fork(watchLogoutUser),
    fork(watchUpdateUser),
    fork(watchFetchUserProfile),
    fork(watchFetchUserImages),
    fork(watchFetchMyReferrals),
    fork(watchFetchReferralStatistics),
    fork(watchRequestRedeemReferral),
  ]);
}
