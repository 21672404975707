import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getRecommendations } from 'services';
import {
  fetchRecommendationsSuccess,
  fetchRecommendationsError,
} from 'store/actions';
import { FETCH_RECOMMENDATIONS } from 'store/actionTypes';

function* fetchRecommendations() {
  try {
    const userObj = yield call(getRecommendations);
    if (!userObj.message) {
      yield put(fetchRecommendationsSuccess(userObj));
    } else {
      yield put(fetchRecommendationsError(userObj));
    }
  } catch (error) {
    yield put(fetchRecommendationsError(error));
  }
}

export function* watchFetchRecommendations() {
  yield takeLatest(FETCH_RECOMMENDATIONS, fetchRecommendations);
}

export default function* rootSaga() {
  yield all([fork(watchFetchRecommendations)]);
}
