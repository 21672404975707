import Slider from 'react-slick';
import { borrowedDashboardBooks } from 'shared/constants/slickConfig';
import { DashboardSliderItem } from 'shared/components';

const DashboardSlider = ({ books, orderHistory, sliderClassNames }) => {
  return (
    <Slider
      className={`slider foruser1 ${sliderClassNames}`}
      {...borrowedDashboardBooks}
    >
      {books.map((book) => {
        const { bookId } = book;
        return (
          <DashboardSliderItem
            key={bookId}
            bookData={book}
            orderHistory={orderHistory}
          />
        );
      })}
    </Slider>
  );
};

export default DashboardSlider;
