import { useState, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import './App.css';

import routes from '../route';
import routeList from 'shared/constants/routes';
import page404Route from 'screens/404/route';

import Header from './Header';
import Footer from './Footer';
import ScrollArrow from './ScrollArrow';
import HealthNotify from './HealthNotify';
import { ImageWithLoading, RouteWithSubRoutes } from 'shared/components';

function App() {
  // const [renderRoutes, changeRenderRoutes] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     changeRenderRoutes(true);
  //     // const script = document.createElement('script');
  //     // script.src = 'https://w.appzi.io/w.js?token=oRI25';
  //     // script.async = true;
  //     // document.body.appendChild(script);
  //   }, 3000);
  // }, []);

  return (
    <ErrorBoundary
      fallback={(props) => <page404Route.component {...props} errorBoundary />}
    >
      <Header />
      <Switch>
        <Redirect exact from="/" to={routeList.HOME.route} />
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
        <Redirect to={page404Route.path} />
      </Switch>
      <ImageWithLoading
        src={require('../../../assets/images/icons/whatsapp-l.png').default}
        alt="wp-contact"
        className="wp-contact"
        onClick={() => {
          window.open('https://wa.me/919980434147', '_blank');
        }}
      />
      <ScrollArrow />
      <HealthNotify />
      <Footer />
    </ErrorBoundary>
  );
}

export default App;
