import axios from 'axios';
import * as apiURL from './apiConfig';

export const addToWishlist = async (wishlistData) => {
  try {
    const response = await axios.post(apiURL.WISHLIST_URL, {
      ...wishlistData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getWishlistItems = async () => {
  try {
    const response = await axios.get(apiURL.WISHLIST_URL);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const deleteWishlistItem = async ({ wishlistItemId }) => {
  try {
    const response = await axios.delete(
      `${apiURL.WISHLIST_URL}/${wishlistItemId}`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
