import axios from 'axios';
import * as apiURL from './apiConfig';

export const registration = async (registrationData) => {
  try {
    const response = await axios.post(apiURL.REGISTRATION_URL, {
      ...registrationData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const login = async (loginData) => {
  try {
    const response = await axios.post(apiURL.LOGIN_URL, {
      ...loginData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const googleLogin = async (loginData) => {
  try {
    const response = await axios.post(apiURL.GOOGLE_LOGIN_URL, {
      ...loginData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const facebookLogin = async (loginData) => {
  try {
    const response = await axios.post(apiURL.FACEBOOK_LOGIN_URL, {
      ...loginData,
    });
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const logout = async () => {
  try {
    const response = await axios.get(apiURL.LOGOUT_URL);
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const changeDefaultChild = async (childData) => {
  try {
    const response = await axios.put(apiURL.CHANGE_DEFAULT_PROFILE, {
      ...childData,
    });
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const requestPasswordReset = async (data) => {
  try {
    const response = await axios.post(apiURL.REQUEST_PASSWORD_RESET, {
      ...data,
    });
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const submitPasswordReset = async (data) => {
  try {
    const response = await axios.post(apiURL.PASSWORD_RESET, {
      ...data,
    });
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const submitNewPassword = async (data) => {
  try {
    const response = await axios.post(apiURL.CHANGE_PASSWORD, {
      ...data,
    });
    return response.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getMyReferrals = async () => {
  try {
    const response = await axios.get(apiURL.MY_REFERRALS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const getReferralStatistics = async () => {
  try {
    const response = await axios.get(apiURL.REFERRAL_STATS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const postRequestRedeemReferrals = async () => {
  try {
    const response = await axios.post(apiURL.REDEEM_REFERRALS);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};

export const submitQuickRegistration = async (data) => {
  try {
    const response = await axios.post(apiURL.QUICK_REGISTER, data);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
