import {
  FETCH_BOOK_RIBBONS,
  FETCH_BOOK_RIBBONS_SUCCESS,
  FETCH_BOOK_RIBBONS_ERROR,
  FETCH_RIBBON_BOOKS,
  FETCH_RIBBON_BOOKS_SUCCESS,
  FETCH_RIBBON_BOOKS_ERROR,
  FETCH_BOOK_DETAILS,
  FETCH_BOOK_DETAILS_SUCCESS,
  FETCH_BOOK_DETAILS_ERROR,
  BROWSE_BOOKS,
  BROWSE_BOOKS_SUCCESS,
  BROWSE_BOOKS_ERROR,
  FETCH_HOME_RIBBONS,
  FETCH_HOME_RIBBONS_SUCCESS,
  FETCH_HOME_RIBBONS_ERROR,
  FETCH_RELATED_BOOKS,
  FETCH_RELATED_BOOKS_SUCCESS,
  FETCH_RELATED_BOOKS_ERROR,
  FETCH_PREVIEW_RIBBONS,
  FETCH_PREVIEW_RIBBONS_SUCCESS,
  FETCH_PREVIEW_RIBBONS_ERROR,
} from '../actionTypes';
import { cloneDeep } from 'lodash';

const ribbonBooks = {
  title: '',
  key: '',
  books: [],
  loadMore: true,
};

const previewRibbons = {
  title: '',
  key: '',
  books: [],
  loadMore: true,
};

const INIT_STATE = {
  error: '',
  loading: false,
  bookRibbons: [],
  ribbonBooks,
  bookDetails: null,
  searchedBooks: [],
  loadSearchedBooks: true,
  homeRibbons: [],
  previewRibbons,
  relatedBooks: [],
  relatedLoading: true,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_BOOK_RIBBONS:
      return { ...state, loading: true, error: '' };
    case FETCH_BOOK_RIBBONS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookRibbons: [...action.payload],
        error: '',
      };
    case FETCH_BOOK_RIBBONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_RIBBON_BOOKS:
      return { ...state, loading: true, error: '' };
    case FETCH_RIBBON_BOOKS_SUCCESS: {
      const { books, append } = action.payload;
      return {
        ...state,
        loading: false,
        ribbonBooks: {
          ...action.payload,
          books: append
            ? state.ribbonBooks.books.concat(books)
            : cloneDeep(books),
          loadMore: !(books.length < 35 || !books.length),
        },
        error: '',
      };
    }
    case FETCH_RIBBON_BOOKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_BOOK_DETAILS:
      return { ...state, loading: true, error: '' };
    case FETCH_BOOK_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookDetails: { ...action.payload },
        error: '',
      };
    case FETCH_BOOK_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case BROWSE_BOOKS:
      return { ...state, loading: true, error: '' };
    case BROWSE_BOOKS_SUCCESS: {
      const { books, append } = action.payload;
      return {
        ...state,
        loading: false,
        searchedBooks: append
          ? state.searchedBooks.concat(books)
          : cloneDeep(books),
        loadSearchedBooks: !(books.length < 35 || !books.length),
        error: '',
      };
    }
    case BROWSE_BOOKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_HOME_RIBBONS:
      return { ...state, loading: true, error: '' };
    case FETCH_HOME_RIBBONS_SUCCESS:
      return {
        ...state,
        loading: false,
        homeRibbons: [...action.payload],
        error: '',
      };
    case FETCH_HOME_RIBBONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_RELATED_BOOKS:
      return { ...state, relatedLoading: true, error: '' };
    case FETCH_RELATED_BOOKS_SUCCESS:
      return {
        ...state,
        relatedLoading: false,
        relatedBooks: [...action.payload],
        error: '',
      };
    case FETCH_RELATED_BOOKS_ERROR:
      return {
        ...state,
        relatedLoading: false,
        error: action.payload,
      };
    // Preview Ribbons
    case FETCH_PREVIEW_RIBBONS:
      return { ...state, loading: true, error: '' };
    case FETCH_PREVIEW_RIBBONS_SUCCESS: {
      const { books, append } = action.payload;
      return {
        ...state,
        loading: false,
        previewRibbons: {
          ...action.payload,
          books: append
            ? state.previewRibbons.books.concat(books)
            : cloneDeep(books),
          loadMore: !(books.length < 35 || !books.length),
        },
        error: '',
      };
    }
    case FETCH_PREVIEW_RIBBONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
