import React from 'react';
import routeConstants from 'shared/constants/routes';
const Home = React.lazy(() => import('./'));

const homeRoute = {
  path: routeConstants.HOME.route,
  component: Home,
};

export default homeRoute;
