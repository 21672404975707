import { useCallback, useEffect, useState } from 'react';
import { useFetchOptions } from 'shared/hooks';

export const useOptionLoader = (apiCall, config) => {
  const [defaultOptions, changeDefaultOptions] = useState([]);
  const fetchOptions = useFetchOptions();

  useEffect(() => {
    const fetchInit = async () => {
      const options = await fetchOptions('', apiCall, config);
      changeDefaultOptions(options);
    };
    fetchInit();
  }, [apiCall, fetchOptions, config]);

  const loadOptions = useCallback(
    async (inputValue, callback) => {
      const options = await fetchOptions(inputValue, apiCall, config);
      callback(options);
    },
    [fetchOptions, apiCall, config]
  );

  return [defaultOptions, loadOptions];
};
