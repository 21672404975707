import React from 'react';
import { routeConstants } from 'shared/constants';
const MyAccount = React.lazy(() => import('./'));

const myAccountRoute = {
  path: routeConstants.ACCOUNT.subRoutes.MY_ACCOUNT.route,
  component: MyAccount,
};

export default myAccountRoute;
