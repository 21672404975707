export const faqs = [
  {
    title: 'How many hours of work is required everyday?',
    content: () => (
      <div className="sinambans">
        Anything between 1-2 hrs is required for daily operations, general
        administrations and marketing activities for the library.
      </div>
    ),
    _id: 'f1',
  },
  {
    title: 'What criteria do I need to fulfill to become a Töölö Ambassador?',
    content: () => (
      <div className="sinambans">
        Some of the criteria that Töölö checks for are:
        <ul>
          <li>Love for books</li>
          <li>Educational qualifications</li>
          <li>Basic computer proficiency</li>
          <li>Time commitment</li>
        </ul>
      </div>
    ),
    _id: 'f2',
  },
  {
    title: 'Do I need to buy books for the library?',
    content: () => (
      <div className="sinambans">
        No, you do not need to buy books. Töölö will provide you with a set of
        500 curated books for your library.
      </div>
    ),
    _id: 'f3',
  },
  {
    title: 'Will I be given any training to operate the library software?',
    content: () => (
      <div className="sinambans">
        Yes, complete assistance will be provided during the onboarding process
        for every new Töölö Ambassador. Our Tech team is always available for
        any assistance required.
      </div>
    ),
    _id: 'f4',
  },
  {
    title:
      'Will I receive assistance if I face any issue after onboarding is complete?',
    content: () => (
      <div className="sinambans">
        Definitely. Our training and support begins on day one and never ends.
        We at Töölö are extremely sensitive about the wholesome experience of
        the Ambassadors. Hence we are always available for support to help you
        run your business successfully.
      </div>
    ),
    _id: 'f5',
  },
  {
    title: 'How much can I earn?',
    content: () => (
      <div className="sinambans">
        As a Töölö Ambassador, your earnings will depend upon how many
        subscribers you can get for your library. However, you can earn anywhere
        between Rs.25,000-Rs.45,000 per month from home.
      </div>
    ),
    _id: 'f6',
  },
  {
    title: 'What is the frequency of payouts for Töölö Ambassadors?',
    content: () => (
      <div className="sinambans">
        Payouts are done on a monthly basis. All payouts are released before 5th
        of every month to our Ambassadors.
      </div>
    ),
    _id: 'f7',
  },
  {
    title: 'Do I need to pay anything to become a Töölö Ambassador?',
    content: () => (
      <div className="sinambans">
        Yes, one needs to pay Rs. 60,000 as fees towards becoming a Töölö
        Ambassador.
        <p>You will receive an initial set of</p>
        <ul>
          <li>500 Curated Books</li>
          <li>Access to a Library Platform</li>
          <li>Branding Materials</li>
          <li>Growth Marketing Tools</li>
          <li>Technical Support</li>
          <li>
            Ongoing knowledge transfer to help you run your business
            successfully.
          </li>
        </ul>
      </div>
    ),
    _id: 'f8',
  },
];

export const preschoolfaqs = [
  {
    title:
      'What is a Töölö-powered library (Library-in-a-box) and what is included? ',
    content: () => (
      <div className="sinambans">
        A Töölö-powered preschool library is an all in one solution for setting
        and starting a library in a flash.
        <p>
          The <b>Library-in-a-box</b> includes:
        </p>
        <ul>
          <li>500 curated and laminated books</li>
          <li>Töölö tracker - reading journal</li>
          <li>Cloth bags </li>
        </ul>
        <p>Marketing material :</p>
        <ul>
          <li>Banner</li>
          <li>Printed brochures</li>
        </ul>
        <p>Services include : </p>
        <ul>
          <li>Tech enabled robust platform </li>
          <li>Platform with end to end solutions</li>
          <li>Platform with end to end solutions</li>
          <li>User friendly website</li>
          <li>Inbuilt books cataloguing</li>
        </ul>
      </div>
    ),
    _id: 'pf1',
  },
  {
    title:
      'Is Töölö a physical reading book library or online reading library? ',
    content: () => (
      <div className="sinambans">
        Töölö is a physical reading book library, where kindergarten children
        get access to physical books online using our tech-integrated Toolo
        dashboard.
      </div>
    ),
    _id: 'pf2',
  },
  {
    title: 'How much would it cost to get a library? ',
    content: () => (
      <div className="sinambans">
        A one-time initial investment of Rs 80,000 is required to get your
        playschool a library which can be paid via bank transfer or UPI.
        <p>
          <b>Is it one-time cost or do we have to pay annually? </b>
        </p>
        <p>
          This is a one time cost. The contract validity is of 3 years after
          which the contract can be renewed with the willingness of both the
          parties.
        </p>
        <p>
          <b>What is the profit sharing percentage?</b>
        </p>
        <p>80:20 on the monthly fee. 80% for Preschool</p>
        <p>
          <b>Is there any additional cost?</b>
        </p>
        <p>
          None. One must have atleast 2 shelves as storage for the books which
          you may need to buy from your end. There are no other hidden costs
          applicable for your preschool.
        </p>
      </div>
    ),
    _id: 'pf3',
  },
  {
    title: 'Can Töölö library help me to increase revenue? ',
    content: () => (
      <div className="sinambans">
        Yes ofcourse! Revenue is generated through the membership fees the
        children are expected to pay to use the library. Preschools earn 80% of
        this revenue .{' '}
        <p>
          For eg: With <b>100 children</b> using the library, preschool stands
          to earn <b>Rs 32,000 per month</b>.
        </p>
      </div>
    ),
    _id: 'pf4',
  },
  {
    title:
      'How many books will we get and how many kids can the book-set cater to?',
    content: () => (
      <div className="sinambans">
        500 curated books will be given in the starter’s kit. 500 books
        comfortably serves 80-100 children of any early learning center.
      </div>
    ),
    _id: 'pf5',
  },
  {
    title: 'What types of books do you provide ?',
    content: () => (
      <div className="sinambans">
        We provide a well-researched curated and customized set of books keeping
        in mind various factors such as age of the child, authors and other
        important criteria etc.
        <p>
          <b>Picture Books:</b> Picture books largely contain clear and big,
          colorful illustrations that help in building vocabulary, and love of
          reading.
        </p>
        <p>
          <b>Board Books:</b> They are sturdy and easy to hold, board books are
          perfect for younger preschoolers. Basic text and vivid, colourful
          graphics aid in developing fine motor abilities.
        </p>
        <p>
          <b>Alphabet and Counting Books:</b> Alphabet and counting books
          contain engaging and simple illustrations of numbers and alphabets so
          that young minds can connect with the graphic.
        </p>
        <p>
          <b>Story Books:</b> Engaging plots, relatable characters, and
          age-appropriate themes helps in developing children's imagination and
          children learn make connections to their own lives.
        </p>
        <p>
          <b>Non-Fiction Books:</b> Factual information based books helps in
          expanding chidlrens knowledge and curiosity.
        </p>
        <p>
          <b>Read it yourself:</b> Perfect books to introduce the idea of
          reading independently. For 2-7 age group, the books usually contain
          easy 3 letter words so as to introduce them to the idea of reading.
        </p>
      </div>
    ),
    _id: 'pf6',
  },
  {
    title:
      'How do you decide the combination of books you provide and what is the age group you cater to?',
    content: () => (
      <div className="sinambans">
        The books are selected by a <b>team of curators</b> headed by our
        founder Sheetal who has{' '}
        <b>researched the public library system of USA and Singapore.</b> The
        team comprises of curators with years of experience and knowledge of
        early childhood education.
        <p>
          Books authored by International & Indian authors cater to{' '}
          <b>2-7 years</b>
          toddlers to early age children. A mix of a wide variety of picture
          books, board books and read it yourself books for the early age
          category are clubbed in <b>500</b> book set.
        </p>
      </div>
    ),
    _id: 'pf7',
  },
  {
    title: 'How much storage space does the library require?',
    content: () => (
      <div className="sinambans">
        To start with 2 standard size shelf ( approximate 35 * 60 inches) units
        is enough to store all the books of the library.
        <p>
          <b>
            Do we need to hire an extra resource to run Töölö-powered library
          </b>
        </p>
        <p>
          Not required. A preschool can easily run the library operations with
          some help from their existing resource itself.
        </p>
      </div>
    ),
    _id: 'pf8',
  },
  {
    title: 'How to integrate library into the curriculum? ',
    content: () => (
      <div className="sinambans">
        Incorporate dedicated reading time (as little as 10 min) for
        kindergarten children on a daily basis. Some other ways of integrating
        the library could be by asking kids to participate in the storytelling
        sessions, read-aloud & other events organized by Töölö on monthly basis.
      </div>
    ),
    _id: 'pf9',
  },
  {
    title: 'How often should we upgrade the book collection?',
    content: () => (
      <div className="sinambans">
        Adding in 10-12 books every 2- 3 months is enough to maintain freshness
        in the library. You can encourage kids to donate books that they have at
        home through Töölö’s donate program. You may also contact us to help you
        upgrade your playschool library from time to time.
      </div>
    ),
    _id: 'pf10',
  },
  {
    title: 'Will I be given any training to operate the library software?',
    content: () => (
      <div className="sinambans">
        Yes, your staff will be trained adequately on the internally developed
        Töölö software.
      </div>
    ),
    _id: 'pf11',
  },
  {
    title:
      'Will I receive assistance if I face any issue after onboarding is complete?',
    content: () => (
      <div className="sinambans">
        Absolutely! You can reach out to us anytime during working hours 10am -
        5pm to get assistance from us.
      </div>
    ),
    _id: 'pf12',
  },
  {
    title: 'What if we want to discontinue and what happens to the books?',
    content: () => (
      <div className="sinambans">
        When a preschool wants to discontinue the library, the library
        operations can simply be closed and refund given to the parents on a pro
        rata basis of the subscription fees already paid by them. Books are your
        asset. They remain with the preschool. As a gesture, we can share the
        list to our existing preschool just in case, they would like to pick it
        up from you. This is not a certainty.
      </div>
    ),
    _id: 'pf13',
  },
];
