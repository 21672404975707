import { useCallback } from 'react';

export const useFetchOptions = () => {
  return useCallback(async (inputValue = '', call, config = {}) => {
    const { label } = config;
    const items = await call({
      config: { params: { search: inputValue } },
    });
    let options = [];
    if (!items.error) {
      options = items.map((item) => {
        const { _id, name } = item;
        return { value: _id, label: label ? item[label] : name };
      });
    }
    return options;
  }, []);
};
