import {
  FETCH_RECOMMENDATIONS,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  recommendationList: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_RECOMMENDATIONS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendationList: [...action.payload],
        error: '',
      };
    case FETCH_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
