import axios from 'axios';
import * as apiURL from './apiConfig';

export const getDonatedBooks = async () => {
  try {
    const response = await axios.get(apiURL.DONATED_BOOKS_URL);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
