import { useField } from 'formik';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
export const FormikDatePicker = ({
  onChange = () => {},
  maxDate,
  minDate,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  return (
    <>
      <Flatpickr
        placeholder="dd-mm-yyyy"
        className="newdatepicker-control "
        name={field.name}
        value={field.value}
        onChange={(val) => {
          setValue(val);
          onChange(meta);
        }}
        calendarClassName="newdatepicker-calender"
        options={{
          minDate:
            minDate ||
            new Date(new Date().setFullYear(new Date().getFullYear() - 72)),
          maxDate: maxDate || new Date(),
        }}
        format="y-MM-dd"
      />
    </>
  );
};
export default FormikDatePicker;
