import React from 'react';
import routeConstants from 'shared/constants/routes';
const Registration = React.lazy(() => import('./'));

const registrationRoute = {
  path: routeConstants.AUTH.subRoutes.REGISTRATION.route,
  component: Registration,
};

export default registrationRoute;
