import { combineReducers } from 'redux';
import auth from './auth/reducer';
import subProfiles from './subProfiles/reducer';
import books from './books/reducer';
import cart from './cart/reducer';
import wishlist from './wishlist/reducer';
import donation from './donation/reducer';
import orders from './orders/reducer';
import subscriptions from './subscriptions/reducer';
import reviews from './reviews/reducer';
import configs from './configs/reducer';
import faqs from './faqs/reducer';
import blogs from './blogs/reducer';
import recommendations from './recommendations/reducer';
import events from './events/reducer';
import fines from './fines/reducer';

const reducers = combineReducers({
  auth,
  subProfiles,
  books,
  cart,
  wishlist,
  donation,
  orders,
  subscriptions,
  reviews,
  configs,
  faqs,
  blogs,
  recommendations,
  events,
  fines,
});

export default reducers;
