import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  addOrder,
  getActiveOrders,
  getOrderHistory,
  getPickupOrders,
} from 'services';
import {
  checkoutSuccess,
  checkoutError,
  fetchCartItemsSuccess,
  fetchCartCountSuccess,
  fetchActiveOrdersSuccess,
  fetchActiveOrdersError,
  fetchOrderHistorySuccess,
  fetchOrderHistoryError,
  fetchPickupOrdersSuccess,
  fetchPickupOrdersError,
} from 'store/actions';
import {
  ADD_ORDER,
  FETCH_ACTIVE_ORDERS,
  FETCH_ORDER_HISTORY,
  FETCH_PICKUP_ORDERS,
} from 'store/actionTypes';

function* addOrderItem() {
  try {
    const userObj = yield call(addOrder);
    if (!userObj.error) {
      yield put(checkoutSuccess(userObj));
      yield put(fetchCartItemsSuccess([]));
      yield put(fetchCartCountSuccess({ count: 0 }));
    } else {
      yield put(checkoutError(userObj.message));
    }
  } catch (error) {
    yield put(checkoutError(error));
  }
}

export function* watchAddOrderItem() {
  yield takeLatest(ADD_ORDER, addOrderItem);
}

function* fetchActiveOrders() {
  try {
    const userObj = yield call(getActiveOrders);
    if (!userObj.error) {
      yield put(fetchActiveOrdersSuccess(userObj));
    } else {
      yield put(fetchActiveOrdersError(userObj.message));
    }
  } catch (error) {
    yield put(fetchActiveOrdersError(error));
  }
}

export function* watchFetchActiveOrders() {
  yield takeLatest(FETCH_ACTIVE_ORDERS, fetchActiveOrders);
}

function* fetchPickupOrders() {
  try {
    const userObj = yield call(getPickupOrders);
    if (!userObj.error) {
      yield put(fetchPickupOrdersSuccess(userObj));
    } else {
      yield put(fetchPickupOrdersError(userObj.message));
    }
  } catch (error) {
    yield put(fetchPickupOrdersError(error));
  }
}

export function* watchFetchPickupOrders() {
  yield takeLatest(FETCH_PICKUP_ORDERS, fetchPickupOrders);
}

function* fetchOrderHistory({ payload }) {
  try {
    const userObj = yield call(getOrderHistory, payload);
    if (!userObj.message) {
      yield put(
        fetchOrderHistorySuccess({ data: [...userObj], append: payload.append })
      );
    } else {
      yield put(fetchOrderHistoryError(userObj));
    }
  } catch (error) {
    yield put(fetchOrderHistoryError(error));
  }
}

export function* watchFetchOrderHistory() {
  yield takeLatest(FETCH_ORDER_HISTORY, fetchOrderHistory);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddOrderItem),
    fork(watchFetchActiveOrders),
    fork(watchFetchPickupOrders),
    fork(watchFetchOrderHistory),
  ]);
}
