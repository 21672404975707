import placeholderImg from 'assets/images/place-holder.jpg';
import Skeleton from 'react-loading-skeleton';

const DashboardRibbonPlaceholder = () => {
  return (
    <>
      <div className="dash-foruser">
        <span>
          <img src={placeholderImg} alt="loading" className="img-responsive" />
        </span>
        Loading...
      </div>
      <Skeleton style={{ minHeight: 220, maxHeight: 220 }} />
    </>
  );
};

export default DashboardRibbonPlaceholder;
