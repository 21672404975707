import React from 'react';
import { routeConstants } from 'shared/constants';
const AmbassadorApply = React.lazy(() => import('./'));

const ambassadorApplyRoute = {
  path: routeConstants.AMBASSADOR_APPLY.route,
  component: AmbassadorApply,
  exact: routeConstants.AMBASSADOR_APPLY.exact,
};

export default ambassadorApplyRoute;
