import React from 'react';
import { routeConstants } from 'shared/constants';
const DonatedBooks = React.lazy(() => import('./'));

const donatedBooksRoute = {
  path: routeConstants.ACCOUNT.subRoutes.DONATED_BOOKS.route,
  component: DonatedBooks,
};

export default donatedBooksRoute;
