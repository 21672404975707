import React from 'react';
import routeConstants from 'shared/constants/routes';
const EventsRegister = React.lazy(() => import('./'));

const EventsRegisterRoute = {
  path: routeConstants.EVENTS_REGISTER.route,
  exact: routeConstants.EVENTS_REGISTER.exact,
  component: EventsRegister,
};

export default EventsRegisterRoute;
