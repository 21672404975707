import { components } from 'react-select';
import selectImage from 'assets/images/icons/select.png';

const DropdownIndicator = ({ indicatorImage, ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={indicatorImage || selectImage}
        alt="location"
        className="toolo-select__indicator__image"
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
