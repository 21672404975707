import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { imageConfigs, routeConstants } from 'shared/constants';
import { ImageWithLoading } from 'shared/components';
import { toggleAccountSidemenu } from 'store/actions';
const { IMAGE_URL } = imageConfigs;

const {
  DASHBOARD,
  MY_ACCOUNT,
  WISHLIST,
  BORROWED_BOOKS,
  // DONATED_BOOKS,
  FINES,
  REFERRALS,
} = routeConstants.ACCOUNT.subRoutes;

const Sidebar = ({ onLogoutClicked, userData }) => {
  const dispatch = useDispatch();
  const { fullName, profileImage, referralCode, enableReferral } = userData;
  const mobileMenu = useSelector((state) => state.configs.sideMenuVisible);
  const fineList = useSelector((state) => state.fines.list);

  const toggleMobileMenu = (value) => {
    dispatch(toggleAccountSidemenu(value));
  };

  /*const handleMenuToggle = (e) => {
    e.stopPropagation();
    if (!mobileMenu) {
      document.body.classList.add('overflowhide');
    } else {
      document.body.classList.remove('overflowhide');
    }
    toggleMobileMenu(!mobileMenu);
  };*/

  const handleOnMenuItemClicked = () => {
    if (mobileMenu) {
      toggleMobileMenu(!mobileMenu);
      document.body.classList.remove('overflowhide');
    }
  };

  let finesClassName = 'fas fa-exclamation-triangle';

  if (fineList && fineList.length) {
    finesClassName = 'fas fa-exclamation-triangle twincle';
  }

  return (
    <div className="dashsidebar" onClick={handleOnMenuItemClicked}>
      <div
        className={`sidebarinner ${
          mobileMenu ? 'mobilemenu-visible' : 'mobilemenu-hide'
        }`}
      >
        <div className="dasboardmenuonlymob">
          <span className="batsdash yesopened">
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </span>
        </div>
        <div className="whologged">
          <div className="shortcutmenus">
            {/*<div className="settingsm">
              <a href="/" onClick={(e) => e.preventDefault()}>
                <img
                  src={require('assets/images/icons/settings.png').default}
                  alt="Setting"
                />
              </a>
            </div>*/}
            {/*<div className="notiemail">
              <a href="/" onClick={(e) => e.preventDefault()}>
                <img
                  src={require('assets/images/icons/messages.png').default}
                  alt="Messages"
                />
                <sup>5</sup>
              </a>
              <a href="/" onClick={(e) => e.preventDefault()}>
                <img
                  src={require('assets/images/icons/notification.png').default}
                  alt="Nofifications"
                />
                <sup>3</sup>
              </a>
            </div>*/}
          </div>
          <div className="profileimgzone">
            <div className="profileimgbox">
              <ImageWithLoading
                src={`${IMAGE_URL}/${profileImage}`}
                alt={fullName}
                className="img-responsive"
              />
              {/*<span>
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <img
                    src={require('assets/images/icons/update-dp.png').default}
                    alt="update-profile"
                  />
                </a>
              </span>*/}
            </div>
          </div>
          <div className="loggedusername">{fullName}</div>
        </div>
        <div className="sidemenulist">
          <h5>MENU</h5>
          <ul>
            <li>
              <NavLink to={DASHBOARD.route} activeClassName="active">
                <i className="fa fa-desktop" />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={BORROWED_BOOKS.route} activeClassName="active">
                <i className="fa fa-book" />
                Borrowed books
              </NavLink>
            </li>
            <li>
              <NavLink to={WISHLIST.route} activeClassName="active">
                <i className="fa fa-heart" />
                Wishlist
              </NavLink>
            </li>

            {/*<li>
              <NavLink to={DONATED_BOOKS.route} activeClassName="active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1"
                  width="37.000000pt"
                  height="32.000000pt"
                  viewBox="0 0 37.000000 32.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path d="M156 311 c5 -5 27 -11 49 -15 39 -6 39 -6 15 8 -27 16 -81 22 -64 7z" />
                    <path d="M280 304 l-25 -15 25 5 c14 3 33 6 43 6 9 0 17 5 17 10 0 15 -31 12 -60 -6z" />
                    <path d="M133 230 c4 -35 10 -66 13 -69 6 -7 202 -7 207 0 3 2 7 33 11 68 l7 64 -48 -8 c-60 -9 -86 -9 -147 1 l-49 7 6 -63z m114 -47 c-2 -16 -4 -5 -4 22 0 28 2 40 4 28 2 -13 2 -35 0 -50z" />
                    <path d="M0 96 c0 -60 21 -96 45 -76 8 7 27 5 61 -5 81 -24 128 -20 200 21 77 43 89 71 19 47 -25 -8 -47 -19 -51 -24 -3 -5 -31 -8 -62 -8 l-57 2 52 6 c28 4 55 9 58 13 13 13 -7 28 -38 28 -18 1 -43 7 -57 15 -14 8 -46 14 -72 15 -26 0 -50 5 -53 10 -3 6 -15 10 -26 10 -16 0 -19 -7 -19 -54z" />
                  </g>
                </svg>
                Donated Books
              </NavLink>
            </li>*/}
            <li>
              <NavLink to={FINES.route} activeClassName="active">
                <i className={finesClassName} />
                Fines
              </NavLink>
            </li>
            {enableReferral ? (
              <li>
                <NavLink to={REFERRALS.route} activeClassName="active">
                  <i className="fa fa-share" />
                  Referrals<sup>New</sup>
                </NavLink>
              </li>
            ) : (
              ''
            )}
          </ul>
          <h5>ACCOUNT</h5>
          <ul>
            <li>
              <NavLink to={MY_ACCOUNT.route} activeClassName="active">
                <i className="fa fa-user-circle" />
                My Account
              </NavLink>
            </li>
            {/*<li>
              <a href="/" onClick={(e) => e.preventDefault()}>
                <i className="fa fa-thumbs-up" />
                My Donation
              </a>
            </li>*/}
            <li>
              <NavLink to={routeConstants.SUBSCRIPTION_PLANS.route}>
                <i className="fa fa-rupee-sign" />
                Subscription Plans
              </NavLink>
            </li>
            <li>
              <a href="/" onClick={onLogoutClicked}>
                <i className="fa fa-power-off" />
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
