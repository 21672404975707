import React from 'react';
import { routeConstants } from 'shared/constants';
const Ambassador = React.lazy(() => import('./'));

const ambassadorRoute = {
  path: routeConstants.AMBASSADOR.route,
  component: Ambassador,
  exact: routeConstants.AMBASSADOR.exact,
};

export default ambassadorRoute;
