import React from 'react';
import routeConstants from 'shared/constants/routes';
const LibraryPreview = React.lazy(() => import('./'));

const libraryPreviewRoute = {
  path: routeConstants.LIBRARY_PREVIEW.route,
  component: LibraryPreview,
};

export default libraryPreviewRoute;
