import {
  FETCH_UPCOMING_EVENTS,
  FETCH_UPCOMING_EVENTS_SUCCESS,
  FETCH_UPCOMING_EVENTS_ERROR,
  FETCH_UPCOMING_EVENT_DETAIL,
  FETCH_UPCOMING_EVENT_DETAIL_SUCCESS,
  FETCH_UPCOMING_EVENT_DETAIL_ERROR,
  FETCH_PAST_EVENTS,
  FETCH_PAST_EVENTS_SUCCESS,
  FETCH_PAST_EVENTS_ERROR,
} from 'store/actionTypes';

export const fetchPastEvents = (data) => ({
  type: FETCH_PAST_EVENTS,
  payload: data,
});

export const fetchPastEventsSuccess = (data) => ({
  type: FETCH_PAST_EVENTS_SUCCESS,
  payload: data,
});

export const fetchPastEventsError = (message) => ({
  type: FETCH_PAST_EVENTS_ERROR,
  payload: message,
});

export const fetchUpcomingEvents = (data) => ({
  type: FETCH_UPCOMING_EVENTS,
  payload: data,
});

export const fetchUpcomingEventsSuccess = (data) => ({
  type: FETCH_UPCOMING_EVENTS_SUCCESS,
  payload: data,
});

export const fetchUpcomingEventsError = (message) => ({
  type: FETCH_UPCOMING_EVENTS_ERROR,
  payload: message,
});

export const fetchUpcomingEventDetail = (data) => ({
  type: FETCH_UPCOMING_EVENT_DETAIL,
  payload: data,
});

export const fetchUpcomingEventDetailSuccess = (data) => ({
  type: FETCH_UPCOMING_EVENT_DETAIL_SUCCESS,
  payload: data,
});

export const fetchUpcomingEventDetailError = (message) => ({
  type: FETCH_UPCOMING_EVENT_DETAIL_ERROR,
  payload: message,
});
