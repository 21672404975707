import React from 'react';
import { routeConstants } from 'shared/constants';
const Error = React.lazy(() => import('./'));

const errorRoute = {
  path: routeConstants.ERROR.route,
  component: Error,
};

export default errorRoute;
