import React from 'react';
import { routeConstants } from 'shared/constants';
const BorrowedBooks = React.lazy(() => import('./'));

const borrowedBooksRoute = {
  path: routeConstants.ACCOUNT.subRoutes.BORROWED_BOOKS.route,
  component: BorrowedBooks,
};

export default borrowedBooksRoute;
