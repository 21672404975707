import React from 'react';
import { routeConstants } from 'shared/constants';
const Faq = React.lazy(() => import('./'));

const faqRoute = {
  path: routeConstants.FAQ.route,
  component: Faq,
};

export default faqRoute;
