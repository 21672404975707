import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getSubscriptions } from 'services';
import {
  fetchSubscriptionsSuccess,
  fetchSubscriptionsError,
} from 'store/actions';
import { FETCH_SUBSCRIPTIONS } from 'store/actionTypes';

function* fetchSubscriptions({ payload }) {
  try {
    const userObj = yield call(getSubscriptions, payload);
    if (!userObj.error) {
      yield put(fetchSubscriptionsSuccess(userObj));
    } else {
      yield put(fetchSubscriptionsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchSubscriptionsError(error));
  }
}

export function* watchFetchSubscriptions() {
  yield takeLatest(FETCH_SUBSCRIPTIONS, fetchSubscriptions);
}

export default function* rootSaga() {
  yield all([fork(watchFetchSubscriptions)]);
}
