import { useState } from 'react';
import { postContactUs } from 'services/adminContactService';
import { toast } from 'react-toastify';

export const useContactService = (type) => {
  const [submitting, changeSubmitting] = useState(false);
  const handleContactSubmit = async (values) => {
    changeSubmitting(true);
    try {
      const response = await postContactUs(values, type);
      if (!response.error) {
        toast.success('We have received your request');
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      toast.error(error);
    }
    changeSubmitting(false);
  };
  return [submitting, handleContactSubmit];
};
