import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { getBookReviews } from 'services';
import { fetchBookReviewsSuccess, fetchBookReviewsError } from 'store/actions';
import { FETCH_BOOK_REVIEWS } from 'store/actionTypes';

function* fetchBookReviews({ payload }) {
  try {
    const userObj = yield call(getBookReviews, payload);
    if (!userObj.message) {
      yield put(fetchBookReviewsSuccess(userObj));
    } else {
      yield put(fetchBookReviewsError(userObj));
    }
  } catch (error) {
    yield put(fetchBookReviewsError(error));
  }
}

export function* watchBookReviews() {
  yield takeLatest(FETCH_BOOK_REVIEWS, fetchBookReviews);
}

export default function* rootSaga() {
  yield all([fork(watchBookReviews)]);
}
