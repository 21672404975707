import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { addToWishlist, getWishlistItems, deleteWishlistItem } from 'services';
import {
  addWishlistItemSuccess,
  addWishlistItemError,
  fetchWishlistItemsSuccess,
  fetchWishlistItemsError,
  removeWishlistItemSuccess,
  removeWishlistItemError,
} from 'store/actions';
import {
  ADD_WISHLIST_ITEM,
  FETCH_WISHLIST_ITEMS,
  REMOVE_WISHLIST_ITEM,
} from 'store/actionTypes';

function* addWishlistItem({ payload }) {
  try {
    const userObj = yield call(addToWishlist, payload);
    if (!userObj.error) {
      yield put(addWishlistItemSuccess(userObj));
      toast.success(userObj.message);
    } else {
      yield put(addWishlistItemError(userObj.message));
    }
  } catch (error) {
    yield put(addWishlistItemError(error));
  }
}

export function* watchAddWishlistItem() {
  yield takeLatest(ADD_WISHLIST_ITEM, addWishlistItem);
}

function* fetchWishlistItems() {
  try {
    const userObj = yield call(getWishlistItems);
    if (!userObj.message) {
      yield put(fetchWishlistItemsSuccess(userObj));
    } else {
      yield put(fetchWishlistItemsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchWishlistItemsError(error));
  }
}

export function* watchFetchWishlistItems() {
  yield takeLatest(FETCH_WISHLIST_ITEMS, fetchWishlistItems);
}

function* removeWishlistItem({ payload }) {
  try {
    const userObj = yield call(deleteWishlistItem, payload);
    if (!userObj.message) {
      yield put(removeWishlistItemSuccess(userObj));
      toast.success('Removed from wishlist');
    } else {
      yield put(removeWishlistItemError(userObj.message));
    }
  } catch (error) {
    yield put(removeWishlistItemError(error));
  }
}

export function* watchRemoveWishlistItem() {
  yield takeLatest(REMOVE_WISHLIST_ITEM, removeWishlistItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddWishlistItem),
    fork(watchFetchWishlistItems),
    fork(watchRemoveWishlistItem),
  ]);
}
