import React from 'react';
import { routeConstants } from 'shared/constants';

const BrowseBooks = React.lazy(() => import('./'));

const browseBooksRoute = {
  path: routeConstants.BROWSE_BOOKS.route,
  component: BrowseBooks,
  exact: routeConstants.BROWSE_BOOKS.exact,
  protected: routeConstants.BROWSE_BOOKS.protected,
};

export default browseBooksRoute;
