import { useField } from 'formik';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { DropdownIndicator, Input } from '../';

const DropDown = ({ label, isCreatable, ...props }) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.

  const [field, meta, helpers] = useField(props);

  const {
    placeHolder,
    disabled,
    defaultOptions,
    indicatorImage,
    options,
    defaultValue,
    selectObjectValue,
    controlled,
    onChange,
  } = props;
  const { setValue } = helpers;

  const handleOnChange = (option) => {
    setValue(selectObjectValue ? option : option.value);
    if (onChange) {
      onChange(option);
    }
  };

  if (controlled !== undefined) {
    return (
      <Select
        options={options}
        name={field.name}
        classNamePrefix="toolo-select"
        onChange={(option) => handleOnChange(option, meta)}
        placeholder={placeHolder}
        aria-label={placeHolder}
        isDisabled={disabled}
        defaultOptions={defaultOptions || false}
        defaultValue={defaultValue || null}
        noOptionsMessage={() => 'Please start typing'}
        inputProps={{ autoComplete: 'new-place' }}
        components={{
          IndicatorSeparator: () => null,
          Input,
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} indicatorImage={indicatorImage} />
          ),
        }}
        value={field.value}
        maxMenuHeight={200}
      />
    );
  } else {
    const SelectComponent = isCreatable ? CreatableSelect : Select;

    return (
      <SelectComponent
        options={options}
        name={field.name}
        classNamePrefix="toolo-select"
        onChange={(option) => handleOnChange(option, meta)}
        placeholder={placeHolder}
        aria-label={placeHolder}
        isDisabled={disabled}
        defaultOptions={defaultOptions || false}
        defaultValue={defaultValue || null}
        noOptionsMessage={() => 'Please start typing'}
        inputProps={{ autoComplete: 'new-place' }}
        components={{
          IndicatorSeparator: () => null,
          Input,
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} indicatorImage={indicatorImage} />
          ),
        }}
        maxMenuHeight={200}
      />
    );
  }
};

export default DropDown;
