import React from 'react';
import { routeConstants } from 'shared/constants';
const Checkout = React.lazy(() => import('./'));

const checkoutRoute = {
  path: routeConstants.CHECKOUT.route,
  component: Checkout,
};

export default checkoutRoute;
