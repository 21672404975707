import {
  ADD_CART_ITEM,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_ERROR,
  FETCH_CART_ITEMS,
  FETCH_CART_ITEMS_SUCCESS,
  FETCH_CART_ITEMS_ERROR,
  REMOVE_CART_ITEM,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_ERROR,
  FETCH_CART_COUNT,
  FETCH_CART_COUNT_SUCCESS,
  FETCH_CART_COUNT_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  itemAdding: '',
  addedItem: null,
  cartData: [],
  cartLoading: false,
  removing: '',
  cartCount: 0,
  counting: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_CART_ITEM:
      return {
        ...state,
        itemAdding: action.payload.bookId,
        error: '',
        addedItem: null,
      };
    case ADD_CART_ITEM_SUCCESS:
      return {
        ...state,
        itemAdding: '',
        addedItem: { ...action.payload },
        cartCount: state.cartCount + 1,
        error: '',
      };
    case ADD_CART_ITEM_ERROR:
      return {
        ...state,
        itemAdding: '',
        error: action.payload.message,
      };
    case FETCH_CART_ITEMS:
      return {
        ...state,
        cartLoading: true,
        cartData: [],
        error: '',
      };
    case FETCH_CART_ITEMS_SUCCESS:
      return {
        ...state,
        cartLoading: false,
        cartData: [...action.payload],
        error: '',
      };
    case FETCH_CART_ITEMS_ERROR:
      return {
        ...state,
        cartLoading: false,
        cartData: [],
        error: action.payload.message,
      };
    case REMOVE_CART_ITEM:
      return {
        ...state,
        removing: action.payload.cartItemId,
        error: '',
        addedItem: null,
      };
    case REMOVE_CART_ITEM_SUCCESS: {
      const selectedChildIndex = state.cartData.findIndex(
        (cartChild) => cartChild.childId === action.payload.childId
      );
      const selectedChild = { ...state.cartData[selectedChildIndex] };
      const newBooks = selectedChild.books.filter(
        (bookData) => bookData.cartItemId !== action.payload.cartItemId
      );
      let newCartData = [...state.cartData];
      if (newBooks.length) {
        selectedChild.books = [...newBooks];
        newCartData[selectedChildIndex] = selectedChild;
      } else {
        newCartData = newCartData.filter(
          (x, index) => index !== selectedChildIndex
        );
      }

      return {
        ...state,
        removing: '',
        cartData: [...newCartData],
        cartCount: state.cartCount - 1,
        error: '',
      };
    }
    case REMOVE_CART_ITEM_ERROR:
      return {
        ...state,
        removing: '',
        error: action.payload.message,
      };
    case FETCH_CART_COUNT:
      return {
        ...state,
        counting: true,
        error: '',
      };
    case FETCH_CART_COUNT_SUCCESS:
      return {
        ...state,
        counting: false,
        cartCount: action.payload.count,
        error: '',
      };
    case FETCH_CART_COUNT_ERROR:
      return {
        ...state,
        counting: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
