import React from 'react';
import { routeConstants } from 'shared/constants';
const BrowseRibbonBooks = React.lazy(() => import('./'));

const browseRibbonBooksRoute = {
  path: routeConstants.BROWSE_RIBBON_BOOKS.route,
  component: BrowseRibbonBooks,
  exact: routeConstants.BROWSE_BOOKS.exact,
};

export default browseRibbonBooksRoute;
