import {
  FETCH_PAST_EVENTS,
  FETCH_PAST_EVENTS_SUCCESS,
  FETCH_PAST_EVENTS_ERROR,
  FETCH_UPCOMING_EVENTS,
  FETCH_UPCOMING_EVENTS_SUCCESS,
  FETCH_UPCOMING_EVENTS_ERROR,
  FETCH_UPCOMING_EVENT_DETAIL,
  FETCH_UPCOMING_EVENT_DETAIL_SUCCESS,
  FETCH_UPCOMING_EVENT_DETAIL_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  pastEvents: [],
  upcomingEvents: [],
  eventDetail: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PAST_EVENTS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pastEvents: [...action.payload],
        error: '',
      };
    case FETCH_PAST_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_UPCOMING_EVENTS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingEvents: [...action.payload],
        error: '',
      };
    case FETCH_UPCOMING_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_UPCOMING_EVENT_DETAIL:
      return {
        ...state,
        loading: true,
        eventDetail: null,
        error: '',
      };
    case FETCH_UPCOMING_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        eventDetail: { ...action.payload },
        error: '',
      };
    case FETCH_UPCOMING_EVENT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
