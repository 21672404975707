import React from 'react';
import { routeConstants } from 'shared/constants';

const Recommendations = React.lazy(() => import('./'));

const recommendationsRoute = {
  path: routeConstants.RECOMMENDATIONS.route,
  component: Recommendations,
};

export default recommendationsRoute;
