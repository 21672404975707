import {
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_ERROR,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,
  FACEBOOK_LOGIN,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_ERROR,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  RESET_LOGOUT_REDIRECTION,
  FETCH_USER_IMAGES,
  FETCH_USER_IMAGES_SUCCESS,
  FETCH_USER_IMAGES_ERROR,
  SET_AUTH_LOADING,
  FETCH_REFERRAL_STATS,
  FETCH_REFERRAL_STATS_SUCCESS,
  FETCH_REFERRAL_STATS_ERROR,
  FETCH_MY_REFERRALS,
  FETCH_MY_REFERRALS_SUCCESS,
  FETCH_MY_REFERRALS_ERROR,
  REQUEST_REDEEM_REFERRALS_SUCCESS,
  REQUEST_REDEEM_REFERRALS_ERROR,
  REQUEST_REDEEM_REFERRALS,
} from '../actionTypes';

const INIT_STATE = {
  signedUser: null,
  registering: false,
  error: '',
  loading: false,
  user: null,
  referralStats: {},
  myReferrals: [],
  redeemReferrals: {},
  redirection: false,
  imagesLoading: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_REGISTRATION:
      return { ...state, registering: true, error: '' };
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        registering: false,
        signedUser: action.payload,
        error: '',
      };
    case USER_REGISTRATION_ERROR:
      return {
        ...state,
        registering: false,
        signedUser: null,
        error: action.payload.message,
      };
    case USER_LOGIN:
      return { ...state, loading: true, error: '' };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: '',
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
      };
    case GOOGLE_LOGIN:
      return { ...state, loading: true, error: '' };
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: '',
      };
    case GOOGLE_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
      };
    case FACEBOOK_LOGIN:
      return { ...state, loading: true, error: '' };
    case FACEBOOK_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: '',
      };
    case FACEBOOK_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        error: '',
        redirection: action.payload
          ? action.payload.redirection || false
          : false,
      };
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
      };
    case RESET_LOGOUT_REDIRECTION:
      return {
        ...state,
        redirection: false,
      };
    case UPDATE_USER:
      return { ...state, loading: true, error: '' };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...action.payload },
        error: '',
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_USER_PROFILE:
      return { ...state, loading: true, error: '' };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...action.payload },
        error: '',
      };
    case FETCH_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_USER_IMAGES:
      return { ...state, imagesLoading: true, error: '' };
    case FETCH_USER_IMAGES_SUCCESS:
      return {
        ...state,
        imagesLoading: false,
        userImages: [...action.payload],
        error: '',
      };
    case FETCH_USER_IMAGES_ERROR:
      return {
        ...state,
        imagesLoading: false,
        error: action.payload,
      };
    case FETCH_REFERRAL_STATS:
      return { ...state, loading: true, error: '' };
    case FETCH_REFERRAL_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        referralStats: { ...action.payload },
        error: '',
      };
    case FETCH_REFERRAL_STATS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_MY_REFERRALS:
      return { ...state, loading: true, error: '' };
    case FETCH_MY_REFERRALS_SUCCESS:
      return {
        ...state,
        loading: false,
        myReferrals: [...action.payload],
        error: '',
      };
    case FETCH_MY_REFERRALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case REQUEST_REDEEM_REFERRALS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case REQUEST_REDEEM_REFERRALS_SUCCESS:
      return {
        ...state,
        loading: false,
        redeemReferrals: { ...action.payload },
        error: '',
      };
    case REQUEST_REDEEM_REFERRALS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
