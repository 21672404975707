import {
  FETCH_BOOK_REVIEWS,
  FETCH_BOOK_REVIEWS_SUCCESS,
  FETCH_BOOK_REVIEWS_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  bookReviews: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_BOOK_REVIEWS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_BOOK_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookReviews: { ...action.payload },
        error: '',
      };
    case FETCH_BOOK_REVIEWS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
