import React from 'react';
import { routeConstants } from 'shared/constants';
const MyFines = React.lazy(() => import('./'));

const myFinesRoute = {
  path: routeConstants.ACCOUNT.subRoutes.FINES.route,
  component: MyFines,
};

export default myFinesRoute;
