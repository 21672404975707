import axios from 'axios';
import * as apiURL from './apiConfig';
import qs from 'query-string';

export const getSubscriptions = async (data) => {
  try {
    const response = await axios.get(
      `${apiURL.SUBSCRIPTION_URL}?${qs.stringify(data)}`
    );
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
