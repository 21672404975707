import {
  FETCH_FINES,
  FETCH_FINES_SUCCESS,
  FETCH_FINES_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  list: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FINES:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_FINES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...action.payload],
        error: '',
      };
    case FETCH_FINES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
