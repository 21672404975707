import { cloneDeep } from 'lodash';
import {
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_ERROR,
  FETCH_ACTIVE_ORDERS,
  FETCH_ACTIVE_ORDERS_SUCCESS,
  FETCH_ACTIVE_ORDERS_ERROR,
  FETCH_PICKUP_ORDERS,
  FETCH_PICKUP_ORDERS_SUCCESS,
  FETCH_PICKUP_ORDERS_ERROR,
  FETCH_ORDER_HISTORY,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  itemAdding: false,
  addedOrder: null,
  activeOrders: [],
  orderHistory: [],
  pickupOrders: [],
  loading: false,
  pickupLoading: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        itemAdding: true,
        error: '',
        addedOrder: null,
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        itemAdding: false,
        addedOrder: [...action.payload],
        error: '',
      };
    case ADD_ORDER_ERROR:
      return {
        ...state,
        itemAdding: false,
        error: action.payload.message,
      };
    case FETCH_ACTIVE_ORDERS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_ACTIVE_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        activeOrders: [...action.payload],
        error: '',
      };
    case FETCH_ACTIVE_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_PICKUP_ORDERS:
      return {
        ...state,
        pickupLoading: true,
        error: '',
      };
    case FETCH_PICKUP_ORDERS_SUCCESS:
      return {
        ...state,
        pickupLoading: false,
        pickupOrders: [...action.payload],
        error: '',
      };
    case FETCH_PICKUP_ORDERS_ERROR:
      return {
        ...state,
        pickupLoading: false,
        error: action.payload.message,
      };
    case FETCH_ORDER_HISTORY:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_ORDER_HISTORY_SUCCESS: {
      const { data, append } = action.payload;
      console.log(action.payload, 'payload');
      //console.log(data, 'data value');

      return {
        ...state,
        loading: false,
        orderHistory: append
          ? state.orderHistory.concat(data)
          : cloneDeep(data),
        loadMore: !(data.length < 10 || !data.length),

        error: '',
      };
    }
    case FETCH_ORDER_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
