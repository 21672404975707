import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  addToCart,
  getCartItems,
  deleteCartItem,
  getCartItemsCount,
} from 'services';
import {
  addCartItemSuccess,
  addCartItemError,
  fetchCartItemsSuccess,
  fetchCartItemsError,
  removeCartItemSuccess,
  removeCartItemError,
  fetchCartCountSuccess,
  fetchCartCountError,
} from 'store/actions';
import {
  ADD_CART_ITEM,
  FETCH_CART_COUNT,
  FETCH_CART_ITEMS,
  REMOVE_CART_ITEM,
} from 'store/actionTypes';

function* addCartItem({ payload }) {
  try {
    const userObj = yield call(addToCart, payload);
    if (!userObj.message) {
      yield put(addCartItemSuccess(userObj));
      toast.success('Added to cart');
    } else {
      yield put(addCartItemError(userObj.message));
    }
  } catch (error) {
    yield put(addCartItemError(error));
  }
}

export function* watchAddCartItem() {
  yield takeLatest(ADD_CART_ITEM, addCartItem);
}

function* fetchCartItems() {
  try {
    const userObj = yield call(getCartItems);
    if (!userObj.message) {
      yield put(fetchCartItemsSuccess(userObj));
    } else {
      yield put(fetchCartItemsError(userObj.message));
    }
  } catch (error) {
    yield put(fetchCartItemsError(error));
  }
}

export function* watchFetchCartItems() {
  yield takeLatest(FETCH_CART_ITEMS, fetchCartItems);
}

function* removeCartItem({ payload }) {
  try {
    const userObj = yield call(deleteCartItem, payload);
    if (!userObj.message) {
      yield put(removeCartItemSuccess(userObj));
    } else {
      yield put(removeCartItemError(userObj.message));
    }
  } catch (error) {
    yield put(removeCartItemError(error));
  }
}

export function* watchRemoveCartItem() {
  yield takeLatest(REMOVE_CART_ITEM, removeCartItem);
}

function* fetchCartCount() {
  try {
    const userObj = yield call(getCartItemsCount);
    if (!userObj.message) {
      yield put(fetchCartCountSuccess(userObj));
    } else {
      yield put(fetchCartCountError(userObj.message));
    }
  } catch (error) {
    yield put(fetchCartCountError(error));
  }
}

export function* watchFetchCartCount() {
  yield takeLatest(FETCH_CART_COUNT, fetchCartCount);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddCartItem),
    fork(watchFetchCartItems),
    fork(watchRemoveCartItem),
    fork(watchFetchCartCount),
  ]);
}
