import {
  ADD_WISHLIST_ITEM,
  ADD_WISHLIST_ITEM_SUCCESS,
  ADD_WISHLIST_ITEM_ERROR,
  FETCH_WISHLIST_ITEMS,
  FETCH_WISHLIST_ITEMS_SUCCESS,
  FETCH_WISHLIST_ITEMS_ERROR,
  REMOVE_WISHLIST_ITEM,
  REMOVE_WISHLIST_ITEM_SUCCESS,
  REMOVE_WISHLIST_ITEM_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  itemAdding: '',
  addedItem: null,
  wishlistData: [],
  wishlistLoading: false,
  removing: '',
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_WISHLIST_ITEM:
      return {
        ...state,
        itemAdding: action.payload.bookId,
        error: '',
        addedItem: null,
      };
    case ADD_WISHLIST_ITEM_SUCCESS:
      return {
        ...state,
        itemAdding: '',
        addedItem: { ...action.payload },
        error: '',
      };
    case ADD_WISHLIST_ITEM_ERROR:
      return {
        ...state,
        itemAdding: '',
        error: action.payload.message,
      };
    case FETCH_WISHLIST_ITEMS:
      return {
        ...state,
        wishlistLoading: true,
        error: '',
      };
    case FETCH_WISHLIST_ITEMS_SUCCESS:
      return {
        ...state,
        wishlistLoading: false,
        wishlistData: [...action.payload],
        error: '',
      };
    case FETCH_WISHLIST_ITEMS_ERROR:
      return {
        ...state,
        wishlistLoading: false,
        error: action.payload.message,
      };
    case REMOVE_WISHLIST_ITEM:
      return {
        ...state,
        removing: action.payload.cartItemId,
        error: '',
        addedItem: null,
      };
    case REMOVE_WISHLIST_ITEM_SUCCESS: {
      const selectedChildIndex = state.wishlistData.findIndex(
        (cartChild) => cartChild.childId === action.payload.childId
      );
      const selectedChild = { ...state.wishlistData[selectedChildIndex] };
      const newBooks = selectedChild.books.filter(
        (bookData) => bookData.wishlistItemId !== action.payload.wishlistItemId
      );
      selectedChild.books = [...newBooks];
      const newWishlistData = [...state.wishlistData];
      newWishlistData[selectedChildIndex] = selectedChild;
      return {
        ...state,
        removing: '',
        wishlistData: [...newWishlistData],
        error: '',
      };
    }
    case REMOVE_WISHLIST_ITEM_ERROR:
      return {
        ...state,
        removing: '',
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
