import axios from 'axios';
import * as apiURL from './apiConfig';

export const getBookReviews = async (bookId) => {
  try {
    const response = await axios.get(`${apiURL.BOOKS_URL}/${bookId}/reviews`);
    return response.data.data;
  } catch (error) {
    error.handleGlobally && error.handleGlobally();
    throw new Error(error);
  }
};
