import { TOGGLE_ACCOUNT_SIDEMENU, TOGGLE_SOCIAL_MODAL } from '../actionTypes';

const INIT_STATE = {
  sideMenuVisible: false,
  socialModalVisible: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_ACCOUNT_SIDEMENU:
      return {
        ...state,
        sideMenuVisible: action.payload,
      };
    case TOGGLE_SOCIAL_MODAL:
      return {
        ...state,
        socialModalVisible: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
