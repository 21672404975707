import {
  ADD_SUB_PROFILE,
  ADD_SUB_PROFILE_SUCCESS,
  ADD_SUB_PROFILE_ERROR,
  FETCH_SUB_PROFILES,
  FETCH_SUB_PROFILES_SUCCESS,
  FETCH_SUB_PROFILES_ERROR,
  EDIT_SUB_PROFILE,
  EDIT_SUB_PROFILE_SUCCESS,
  EDIT_SUB_PROFILE_ERROR,
  REMOVE_SUB_PROFILE,
  REMOVE_SUB_PROFILE_SUCCESS,
  REMOVE_SUB_PROFILE_ERROR,
} from '../actionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  addProfileData: null,
  profiles: [],
  editProfileData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_SUB_PROFILE:
      return { ...state, loading: true, error: '', addProfileData: null };
    case ADD_SUB_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        addProfileData: { ...action.payload },
        error: '',
      };
    case ADD_SUB_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case FETCH_SUB_PROFILES:
      return { ...state, loading: true, error: '' };
    case FETCH_SUB_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: [...action.payload],
        error: '',
      };
    case FETCH_SUB_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case EDIT_SUB_PROFILE:
      return { ...state, loading: true, error: '', editProfileData: null };
    case EDIT_SUB_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        editProfileData: { ...action.payload },
        error: '',
      };
    case EDIT_SUB_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case REMOVE_SUB_PROFILE:
      return { ...state, loading: true, error: '' };
    case REMOVE_SUB_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case REMOVE_SUB_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default reducer;
